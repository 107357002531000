import React from "react";

// Components
import BaseListViewComponent from "./BaseListViewComponent";

// Functions
import * as Constants from "./Constants";
import * as Helper from "./Helper";
import numeral from "numeral";

class BillingList extends BaseListViewComponent {
  constructor(props) {
    super(props);
    // Override default state here
    this.state = {
      ...this.state,
      hideFilter: true,
    };
  }

  getHeaderRowItems = () => {
    if (
      this.props.filtertype?.tab === Constants.TAB_ACTIVE ||
      this.props.filtertype?.tab === Constants.TAB_INACTIVE ||
      this.props.filtertype?.tab === Constants.TAB_OWING
    ) {
      return this.getHeaderRowBilling();
    } else if (this.props.filtertype?.tab === Constants.TAB_PLANS) {
      return this.getHeaderRowPlans();
    }
  };

  getHeaderRowBilling = () => {
    return [
      {
        classes: "header firstLeft",
        sortable: true,
        columnheading: "Customer",
        sortkey: "customer_first_name",
      },
      {
        classes: "header desktop",
        sortable: false,
        columnheading: "Phone Number",
        sortkey: "customer_phone",
      },
      {
        classes: "header mobile",
        sortable: false,
        columnheading: "Phone",
        sortkey: "customer_phone",
      },
      {
        classes: "header desktop",
        sortable: true,
        columnheading: "Plan Name", // Labelled as "name" but uses "plan_desc" field
        sortkey: "plan_desc",
      },
      {
        classes: "header centerAligned desktop",
        sortable: true,
        columnheading: "Status",
        sortkey: "status",
      },
      {
        classes: "header right-aligned desktop",
        sortable: true,
        columnheading: "Draft Amount",
        sortkey: "recur_amt",
      },
      {
        classes: "header right-aligned desktop",
        sortable: false,
        columnheading: "Current Balance",
        sortkey: "amt_overdue_total",
      },
      {
        classes: "header right-aligned mobile",
        sortable: true,
        columnheading: "Balance",
        sortkey: "amt_overdue_total",
      },
      {
        classes: "header right-aligned desktop lastRight",
        sortable: true,
        columnheading: "Next Draft",
        sortkey: "date_next",
      },
    ];
  };

  getHeaderRowPlans = () => {
    return [
      {
        classes: "header desktop firstLeft",
        sortable: true,
        columnheading: "Type",
        sortkey: "plan_code",
      },
      {
        classes: "header",
        sortable: true,
        columnheading: "Plan Name",
        sortkey: "plan_desc",
      },
      {
        classes: "header centerAligned desktop",
        sortable: true,
        columnheading: "Status",
        sortkey: "status",
      },
      {
        classes: "header right-aligned",
        sortable: true,
        columnheading: "Amount",
        sortkey: "amt_tran",
      },
      {
        classes: "header centerAligned",
        sortable: true,
        columnheading: "Frequency",
        sortkey: "plan_frequency",
      },
      {
        classes: "header centerAligned desktop lastRight",
        sortable: true,
        columnheading: "Duration",
        sortkey: "plan_duration",
      },
    ];
  };

  renderItemToColumns = item => {
    if (
      this.props.filtertype?.tab === Constants.TAB_ACTIVE ||
      this.props.filtertype?.tab === Constants.TAB_INACTIVE ||
      this.props.filtertype?.tab === Constants.TAB_OWING
    ) {
      return this.renderItemToColumnsBilling(item);
    } else if (this.props.filtertype?.tab === Constants.TAB_PLANS) {
      return this.renderItemToColumnsPlans(item);
    } else {
      return [];
    }
  };

  renderItemToColumnsBilling = item => {
    const recurAmount = numeral(item.recur_amt).format(Constants.CURRENCY);
    const overdueAmount = numeral(item.amt_overdue_total).format(Constants.CURRENCY);
    const customername = item.customer_first_name + " " + item.customer_last_name;
    const desktopBalanceClasses = "right-aligned desktop " + (numeral(item.amt_overdue_total).value() > 0 ? " highlight " : "");
    const mobileBalanceClasses =
      "right-aligned mobile balanceBadge " + item.status + " " + (numeral(item.amt_overdue_total).value() > 0 ? " highlight " : "");
    const mobileNameClasses = "mobile balanceBadge " + item.status;
    const customerPhone = Helper.formatPhoneNumber(item.customer?.customer_phone);
    return [
      { rowvalue: customername, classes: "desktop" },
      { rowvalue: customername, classes: mobileNameClasses },
      { rowvalue: customerPhone, classes: "desktop" },
      { rowvalue: customerPhone, classes: mobileNameClasses },
      { rowvalue: item.plan_desc, classes: "desktop" },
      { rowvalue: Helper.renderStatus(item.status), classes: "centerAligned desktop" },
      { rowvalue: recurAmount, classes: "right-aligned desktop" },
      { rowvalue: overdueAmount, classes: desktopBalanceClasses },
      { rowvalue: overdueAmount, classes: mobileBalanceClasses },
      { rowvalue: Helper.formatDate(item.date_next, false), classes: "right-aligned desktop" },
    ];
  };

  renderItemToColumnsPlans = plan => {
    let duration = plan.plan_duration;
    if (plan.plan_duration === -1) {
      duration = "Until Cancelled";
    }
    let status = Helper.renderMaastPlanStatus(plan.status);
    const plan_type_label = plan.is_individual ? "Individual" : "Group";
    return [
      { rowvalue: plan_type_label, classes: "desktop" },
      { rowvalue: plan.plan_desc, classes: "" },
      { rowvalue: status, classes: "centerAligned desktop" },
      { rowvalue: numeral(plan.amt_tran).format(Constants.CURRENCY), classes: "right-aligned" },
      { rowvalue: Helper.renderMaastFrequency(plan.plan_frequency), classes: "centerAligned" },
      { rowvalue: duration, classes: "centerAligned desktop" },
    ];
  };

  renderTopControlButtons = () => {
    let activeTabClassName = "action-tab";
    let inactiveTabClassName = "action-tab";
    let owingTabClassName = "action-tab";
    let plansTabClassName = "action-tab desktop";

    if (this.props.filtertype?.tab === Constants.TAB_ACTIVE) {
      activeTabClassName += " selected ";
    } else if (this.props.filtertype?.tab === Constants.TAB_INACTIVE) {
      inactiveTabClassName += " selected ";
    } else if (this.props.filtertype?.tab === Constants.TAB_OWING) {
      owingTabClassName += " selected ";
    } else if (this.props.filtertype?.tab === Constants.TAB_PLANS) {
      plansTabClassName += " selected ";
    }

    return (
      <React.Fragment>
        <div className="billinglisttopcontrols">
          <span
            className={activeTabClassName}
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.TAB_ACTIVE });
              this.handleFilterList({ tab: Constants.TAB_ACTIVE });
            }}
          >
            Active
          </span>
          <span
            className={inactiveTabClassName}
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.TAB_INACTIVE });
              this.handleFilterList({ tab: Constants.TAB_INACTIVE });
            }}
          >
            Inactive
          </span>
          <span
            className={owingTabClassName}
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.TAB_OWING });
              this.handleFilterList({ tab: Constants.TAB_OWING });
            }}
          >
            Owing
          </span>
          <span
            className={plansTabClassName}
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.TAB_PLANS });
              this.handleFilterList({ tab: Constants.TAB_PLANS });
            }}
          >
            Plans
          </span>
        </div>
        <span className="desktop"></span>
        <span className="action-tab-balance"></span>
      </React.Fragment>
    );
  };

  getListGridClassName = () => {
    if (this.props.filtertype?.tab === Constants.TAB_ACTIVE) {
      return "billinglist active";
    } else if (this.props.filtertype?.tab === Constants.TAB_INACTIVE) {
      return "billinglist inactive";
    } else if (this.props.filtertype?.tab === Constants.TAB_OWING) {
      return "billinglist owing";
    } else if (this.props.filtertype?.tab === Constants.TAB_PLANS) {
      return "billinglist plans";
    }
  };
}
export default BillingList;
