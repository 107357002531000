import React from "react";

// Components
import BaseListViewComponent from "./BaseListViewComponent";

// Functions
import * as Constants from "./Constants";
import * as Helper from "./Helper";

class QuoteList extends BaseListViewComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      hideFilter: true,
    };
  }

  getHeaderRowItems = () => {
    return [
      { classes: "header firstLeft", sortable: true, columnheading: "Date", sortkey: "orders.creationdatetime" },
      { classes: "header desktop", sortable: true, columnheading: "Quote", sortkey: "orders.ordernumber" },
      { classes: "header", sortable: true, columnheading: "Customer", sortkey: "orders.contactname" },
      { classes: "header desktop", columnheading: "Phone" },
      { classes: "header desktop", sortable: true, columnheading: "Product", sortkey: "first_item.productname" },
      { classes: "header desktop right-aligned", sortable: true, columnheading: "Total", sortkey: "orders.totalprice" },
      { classes: "header centerAligned lastRight", sortable: true, columnheading: "Status", sortkey: "orders.orderstatus" },
    ];
  };

  renderItemToColumns = item => {
    // Each tab has the same columns to display, except for the open tab and it doesn't display status
    const mobileCreationDate = <span data-testid="Mobile Creation Date"> {Helper.formatDate(item.creationdatetime, true)}</span>;
    const desktopCreationDate = <span data-testid="Desktop Creation Date"> {Helper.formatDate(item.creationdatetime, false)}</span>;
    const orderNumber = Helper.renderOrderNumber(item?.ordertype, item?.ordersubtype, item?.ordernumber, item?.externalid, this.props.filtertype);
    const customername = Helper.renderCustomerName(item.contactname, item.companyname);
    const phone = Helper.renderPhone(item.mobilephone, item.otherphone);
    const productname = Helper.renderProductName(item.productname, item.itemcount);
    const totalPricePlusTax = Helper.renderTotalPricePlusTax(item.totalpriceplustax);
    const orderstatus = Helper.renderStatus(item.orderstatus, item.ordersubtype);

    return [
      { rowvalue: mobileCreationDate, classes: "mobile" },
      { rowvalue: desktopCreationDate, classes: "desktop" },
      { rowvalue: orderNumber, classes: "desktop" },
      { rowvalue: customername },
      { rowvalue: phone, classes: "desktop" },
      { rowvalue: productname, classes: "desktop" },
      { rowvalue: totalPricePlusTax, classes: "right-aligned" },
      { rowvalue: orderstatus, classes: "right-aligned" },
    ];
  };

  renderTopControlButtons = () => {
    let openTabClassName = "action-tab";
    let processedTabClassName = "action-tab";
    let allTabClassName = "action-tab";
    if (this.props.filtertype.tab === Constants.ORDER_STATUS_OPEN) {
      openTabClassName += " selected ";
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_PROCESSED) {
      processedTabClassName += " selected ";
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_ALL) {
      allTabClassName += " selected ";
    }

    return (
      <React.Fragment>
        <div className="orderlisttopcontrols">
          <span
            className={openTabClassName}
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.ORDER_STATUS_OPEN });
              this.handleFilterList({ tab: Constants.ORDER_STATUS_OPEN });
            }}
          >
            Open
          </span>
          <span
            className={processedTabClassName}
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.ORDER_STATUS_PROCESSED });
              this.handleFilterList({ tab: Constants.ORDER_STATUS_PROCESSED });
            }}
          >
            Processed
          </span>
          <span
            className={allTabClassName}
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.ORDER_STATUS_ALL });
              this.handleFilterList({ tab: Constants.ORDER_STATUS_ALL });
            }}
          >
            All
          </span>
        </div>
        <span className="desktop"></span>
        <span className="action-tab-balance">
          <span></span>
          {this.renderCalendarFilter()}
        </span>
      </React.Fragment>
    );
  };

  getListGridClassName = () => {
    if (this.props.filtertype.tab === Constants.ORDER_STATUS_OPEN) {
      return "quotelist open";
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_PROCESSED) {
      return "quotelist processed";
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_ALL) {
      return "quotelist all";
    }
  };

  getOrderType = () => {
    return Constants.INVOICE;
  };
}
export default QuoteList;
