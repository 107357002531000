import React from "react";

// Constants
import * as Constants from "./Constants";
import * as Helper from "./Helper";

// Components
import ArrowDownIcon from "./img/ArrowDownIcon";
import BaseListViewComponent from "./BaseListViewComponent";
import FlexInput from "./FlexInput";
import SettingsItemList from "./SettingsItemList";
import Switch from "./Switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

// View for editing a supplier.
class Settings extends BaseListViewComponent {
  constructor(props) {
    super(props);
    let features = props.appState?.features;
    // this.settingsSearchInput = React.createRef();
    let listItems = [];
    listItems.push({ uuid: "Client Information" });
    listItems.push({ uuid: "Users" });
    if (features?.includes(Constants.FEATURE_PRODUCTS)) {
      listItems.push({ uuid: "Sales Tax" });
    }
    listItems.push({ uuid: "Credit Card" });
    if (features?.includes(Constants.FEATURE_PRODUCTS)) {
      listItems.push({ uuid: "Gift Cards" });
    }
    if (features?.includes(Constants.FEATURE_EMAIL)) {
      listItems.push({ uuid: "Messages" });
    }
    listItems.push({ uuid: "Display" });
    if (features?.includes(Constants.FEATURE_REPAIRS)) {
      listItems.push({ uuid: "Service" });
    }
    if (features?.includes(Constants.FEATURE_PRODUCTS)) {
      listItems.push({ uuid: "Third Party" });
    }
    if (features?.includes(Constants.FEATURE_BILLING)) {
      listItems.push({ uuid: "Custom Fields" });
    }
    if (props.appState.usertype >= Constants.USER_TYPE_ADMIN) {
      listItems.push({ uuid: "Administration" });
    }
    // Add new settings categories here as needed

    this.state = {
      deletedSettings: [],
      hideFilter: true,
      hideFilterDates: true,
      hideNewButton: true,
      settings: [],
      settingsSearch: "",
      showsearch: false,
      technicians: [],
      expandedListItems: null,
      listItems: listItems,
      selectedListItems: [listItems[0]],
    };
  }

  // Once component is loaded, fire GET request(s)
  componentDidMount() {
    this.getUsers();
    this.getClient(() => {
      this.getEnterprises();
    });
    // Get the settings
    this.getSettings(() => {
      // then get the terminals
      this.getTerminals();
      // Check for email address verification
      this.maybeCheckEmailVerification();
    });
  }

  getHeaderRowItems = () => {
    return null;
  };

  getOrderType = () => {
    return Constants.SETTINGS;
  };

  getListGridClassName = () => {
    return "settingslist";
  };

  renderItemToColumns = item => {
    return [{ rowvalue: item.uuid }];
  };

  renderMobileDetailSelector = () => {
    return (
      <div className="mobile settingCategory">
        Select setting: &nbsp;
        <select
          name="settingCategory"
          onChange={event =>
            this.setState(prevState => ({
              selectedListItems: prevState.listItems.filter(item => item.uuid === event.target.value),
            }))
          }
          value={this.state.selectedListItems[0].uuid || ""}
        >
          {this.renderSettingCategoryOptions()}
        </select>
      </div>
    );
  };

  renderSettingCategoryOptions = () => {
    const options = this.state.listItems.map(item => item.uuid);
    return options.map(option => {
      return (
        <option key={option} value={option} id={option}>
          {option}
        </option>
      );
    });
  };

  renderDetailPanel = () => {
    if (this.state.selectedListItems[0].uuid === "Client Information") {
      return this.renderGeneralSettings();
    } else if (this.state.selectedListItems[0].uuid === "Service") {
      return this.renderRepairSettings();
    } else if (this.state.selectedListItems[0].uuid === "Messages") {
      return this.renderMessagesSettings();
    } else if (this.state.selectedListItems[0].uuid === "Third Party") {
      return this.renderThirdPartySettings();
    } else if (this.state.selectedListItems[0].uuid === "Display") {
      return this.renderDisplaySettings();
    } else if (this.state.selectedListItems[0].uuid === "Gift Cards") {
      return this.renderGiftCardSettings();
    } else if (this.state.selectedListItems[0].uuid === "Credit Card") {
      return this.renderCreditCardSettings();
    } else if (this.state.selectedListItems[0].uuid === "Sales Tax") {
      return this.renderSalesTaxSettings();
    } else if (this.state.selectedListItems[0].uuid === "Custom Fields") {
      return this.renderCustomDataSettings();
    } else if (this.state.selectedListItems[0].uuid === "Users") {
      return this.renderUsersSettings();
    } else if (this.state.selectedListItems[0].uuid === "Administration") {
      return this.renderAdmin();
    } else {
      return "Select a category";
    }
  };

  renderGeneralSettings = () => {
    // Client Logo
    const logouuid = this.state.settings.find(
      setting => setting.category === Constants.SETTINGS_CATEGORY_CLIENT && setting.description === Constants.SETTINGS_LOGO
    )?.value;
    let currentLogo = "";
    if (logouuid) {
      const src = this.props.appState.uploadsUrl + "?uploaduuid=" + logouuid;
      currentLogo = <img className="productPhoto clientLogo" alt="Client logo" src={src} />;
    }
    let newLogo = (
      <div className="photoListItem placeholder" key="placeholder">
        <ArrowDownIcon />
        <div className="centerAligned">Drag SVG file here to replace the current logo</div>
      </div>
    );
    let merchant_tc = "";
    let enterpriseSelect = "";
    let planSelect = "";
    if (this.props.appState.usertype >= Constants.USER_TYPE_ADMIN && this.state.client) {
      // Subdomain Name for SMS Terms and Conditions. Only allow admins to view/edit.
      merchant_tc = (
        <div className="settingInputItem">
          <label htmlFor="subdomain">Subdomain &nbsp;</label>
          {this.renderSubdomainInput(false)}
        </div>
      );
      // Enterprise Name for Multi Store Clients. Only allow admins to view/edit.
      let enterprises = Helper.deepCopy(this.state.enterprises ?? []);
      enterprises = enterprises.map(enterprise => {
        return { uuid: enterprise.enterprisename, enterprisename: enterprise.enterprisename };
      });
      enterprises.unshift({ uuid: "-1", enterprisename: "Non Enterprise Client" });
      enterprises.push({ uuid: "0", enterprisename: "[Add New Enterprise]" });
      const optionList = enterprises?.map(enterprise => {
        return (
          <option key={enterprise.enterprisename} value={enterprise.uuid}>
            {enterprise.enterprisename}
          </option>
        );
      });
      enterpriseSelect = (
        <div className="settingInputItem">
          <label htmlFor="enterprise">Enterprise</label>
          <select
            name="enterprise"
            id="enterprise"
            data-testid="Enterprise"
            onChange={event => {
              // Add new enterprise
              if (event.target.value === "0") {
                this.handleAddEnterprise();
              }
              // Clear enterprise
              else if (event.target.value === "-1") {
                this.handleClearEnterprise();
              }
              // Set enterprise
              else {
                this.handleSetEnterprise(event.target.value);
              }
              this.setState(prevState => ({
                client: {
                  ...prevState.client,
                  enterprisename: event.target.value,
                },
              }));
            }}
            value={this.state.client?.enterprisename}
          >
            {optionList}
          </select>
        </div>
      );
      // Selected Plan Code for Client. Only allow admins to view/edit.
      let planOptionList = ["BHBASIC", "BHPLUS", "CHPLUS", "CHPRO", "CHPROECOMM", "CHFOUNDER"].map(planCode => {
        return (
          <option key={planCode} value={planCode}>
            {planCode}
          </option>
        );
      });
      planSelect = (
        <div className="settingInputItem">
          <label htmlFor="plan">Plan Code</label>
          <select
            name="plan"
            id="plan"
            onChange={event => {
              // Add new plan
              if (event.target.value === "0") {
                this.handleAddPlan();
              }
              // Clear plan
              else if (event.target.value === "-1") {
                this.handleClearPlan();
              }
              // Set plan
              else {
                this.handleSetplan(event.target.value);
              }
              this.setState(prevState => ({
                client: {
                  ...prevState.client,
                  planname: event.target.value,
                },
              }));
            }}
            value={this.getValue(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_PLAN_CODE)}
          >
            {planOptionList}
          </select>
        </div>
      );
    }

    return (
      <div className="generalSettings">
        <div>
          <h2 className="component-title settingsH3">Client Information</h2>
          <div className="italic settinginstructions">Client Information will display on all printed materials.</div>
          <div className="settingInputItem">
            <input
              type="text"
              name="storename"
              id="storename"
              placeholder="Store name"
              maxLength={255}
              autoComplete="off"
              onFocus={Helper.handleFocus}
              onChange={event => {
                this.handleChangeValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_NAME));
              }}
              onBlur={event => {
                this.handleBlurValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_NAME));
              }}
              value={this.getValue(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_NAME)}
            />
          </div>
          <div className="settingInputItem">
            <input
              type="text"
              name="streetaddress"
              id="streetaddress"
              placeholder="Street address"
              maxLength={255}
              autoComplete="off"
              onFocus={Helper.handleFocus}
              onChange={event => {
                this.handleChangeValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_STREET_ADDRESS));
              }}
              onBlur={event => {
                this.handleBlurValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_STREET_ADDRESS));
              }}
              value={this.getValue(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_STREET_ADDRESS)}
            />
          </div>
          <div className="settingInputItem">
            <input
              type="text"
              name="csz"
              id="csz"
              placeholder="City, State Zip"
              maxLength={255}
              autoComplete="off"
              onFocus={Helper.handleFocus}
              onChange={event => {
                this.handleChangeValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_CITY_ST_ZIP));
              }}
              onBlur={event => {
                this.handleBlurValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_CITY_ST_ZIP));
              }}
              value={this.getValue(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_CITY_ST_ZIP)}
            />
          </div>
          <div className="settingInputItem">
            <input
              type="text"
              name="phone"
              id="phone"
              placeholder="Phone"
              maxLength={255}
              autoComplete="off"
              onFocus={Helper.handleFocus}
              onChange={event => {
                this.handleChangeValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_PHONE));
              }}
              onBlur={event => {
                this.handleBlurValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_PHONE));
              }}
              value={this.getValue(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_PHONE)}
            />
          </div>
          <div className="settingInputItem">
            <input
              type="text"
              name="website"
              id="website"
              placeholder="Website"
              maxLength={255}
              autoComplete="off"
              onFocus={Helper.handleFocus}
              onChange={event => {
                this.handleChangeValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_WEBSITE));
              }}
              onBlur={event => {
                this.handleBlurValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_WEBSITE));
              }}
              value={this.getValue(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_WEBSITE)}
            />
          </div>
          <div className="settingInputItem">
            <input
              type="text"
              name="email"
              id="email"
              placeholder="Email address"
              maxLength={255}
              autoComplete="off"
              onFocus={Helper.handleFocus}
              onChange={event => {
                this.handleChangeValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_EMAIL));
              }}
              onBlur={event => {
                this.handleBlurValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_EMAIL), "email");
              }}
              value={this.getValue(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_EMAIL)}
            />
          </div>
          <div className="settingInputItem">
            <input
              type="text"
              name="textid"
              id="textid"
              placeholder="Federal Tax ID"
              maxLength={255}
              autoComplete="off"
              onFocus={Helper.handleFocus}
              onChange={event => {
                this.handleChangeValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_TAX_ID));
              }}
              onBlur={event => {
                this.handleBlurValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_TAX_ID));
              }}
              value={this.getValue(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_TAX_ID)}
            />
          </div>
          <div className="settingInputItem">
            <label htmlFor="timezone">Timezone</label>
            <select
              name="timezone"
              id="timezone"
              onChange={event => {
                this.handleChangeValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_TIMEZONE));
              }}
              value={this.getValue(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_TIMEZONE)}
            >
              <option value="US/Alaska">Alaska</option>
              <option value="US/Central">Central</option>
              <option value="US/Eastern">Eastern</option>
              <option value="US/Hawaii">Hawaii</option>
              <option value="US/Mountain">Mountain</option>
              <option value="US/Pacific">Pacific</option>
            </select>
          </div>
          {enterpriseSelect}
          {planSelect}
          {merchant_tc}
        </div>
        <div>
          <h2 className="component-title settingsH3">Client Logo</h2>
          <div className="italic settinginstructions">
            Client logo must be an SVG with an aspect ratio of 1:1. A viewbox size of 500x500 is recommended.
          </div>
          <div
            className="photoListItems"
            onDrop={e => {
              Helper.findParent(e.target, "placeholder").classList.remove("dragOver");
              this.handleDropPhoto(e);
            }}
            onDragOver={e => {
              e.preventDefault();
            }}
            onDragEnter={e => {
              e.preventDefault();
              if (e.target.classList.contains("placeholder")) {
                e.target.classList.add("dragOver");
              }
            }}
            onDragExit={e => {
              e.preventDefault();
              // Only remove the class if leaving the parent element, not a nested element
              if (e.target.classList.contains("placeholder") && !Helper.findParent(e.relatedTarget, "placeholder")) {
                e.target.classList.remove("dragOver");
              }
            }}
            onDragLeave={e => {
              e.preventDefault();
              // Only remove the class if leaving the parent element, not a nested element
              if (e.target.classList.contains("placeholder") && !Helper.findParent(e.relatedTarget, "placeholder")) {
                e.target.classList.remove("dragOver");
              }
            }}
            onDragEnd={e => {
              e.preventDefault();
              // Only remove the class if leaving the parent element, not a nested element
              if (e.target.classList.contains("placeholder") && !Helper.findParent(e.relatedTarget, "placeholder")) {
                e.target.classList.remove("dragOver");
              }
            }}
          >
            {currentLogo}
            {newLogo}
          </div>
        </div>
      </div>
    );
  };

  renderMessagesSettings = () => {
    const verifiedSetting = this.getValue(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_FROM_VERIFIED);
    const from_address = this.getValue(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_FROM_ADDRESS);
    const verified = verifiedSetting && Helper.isTrue(verifiedSetting);
    const reverifyButton =
      verifiedSetting === Constants.MAYBE ? (
        <span
          className="action-button green-button verifyEmail"
          onClick={() => {
            this.getCheckEmailVerification(response => {
              if (response.body.message) {
                this.props.showOverlay({
                  text: response.body.message,
                  type: Constants.OVERLAY_MESSAGE,
                });
              }
            });
          }}
        >
          Check Verification Status
        </span>
      ) : (
        ""
      );
    const verifyButtonClasses = "action-button verifyEmail " + (verifiedSetting === Constants.MAYBE ? "brown-button" : "green-button");
    const verifyEmailAddress =
      !verifiedSetting || verified || !from_address ? (
        ""
      ) : (
        <div>
          <div className="italic verifyEmailinstructions highlight">
            To verify your email address, click the button below. You will recieve an email from Amazon Web Services (AWS). Click the link in the
            message to complete the verification process.
          </div>
          <div className="twoColumnGrid">
            <span
              className={verifyButtonClasses}
              onClick={() => {
                this.props.handleVerifyEmail(verified => {
                  this.setState(prevState => ({
                    settings: prevState.settings.map(item => {
                      if (item.category === Constants.SETTINGS_CATEGORY_CLIENT && item.description === Constants.SETTINGS_FROM_VERIFIED) {
                        item.value = verified ? Constants.TRUE : Constants.MAYBE;
                      }
                      return item;
                    }),
                  }));
                });
              }}
            >
              {verifiedSetting === Constants.MAYBE ? "Resend Verification Email" : "Send Verification Email"}
            </span>
            {reverifyButton}
          </div>
        </div>
      );
    const classes = this.getValue(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_FROM_ADDRESS).toLowerCase().endsWith("yahoo.com")
      ? "badEmail"
      : "";

    const merchant_tc_link = this.state.client?.subdomain ? (
      <div className="span2">
        Texting Terms & Conditions and Privacy Policy can be found at{" "}
        <a className="inlineLink" href={"https://" + this.state.client?.subdomain + ".billinghound.com"} target="_blank" rel="noreferrer">
          {"https://" + this.state.client?.subdomain + ".billinghound.com"}
        </a>
        .
      </div>
    ) : (
      ""
    );

    // Render SMS settings, if feature is enabled
    const smsSettings = this.props.appState?.features?.includes(Constants.FEATURE_TEXT) ? (
      <React.Fragment>
        <div className="italic settinginstructions">
          <span className="orange">Information-only</span> settings used for text messaging within ClerkHound
        </div>
        <div className="settingInputItem ">
          <label className="inputLabel" htmlFor="phonenumber">
            SMS Phone Number
          </label>
          <input
            type="text"
            name="phonenumber"
            id="phonenumber"
            maxLength={50}
            autoComplete="off"
            readOnly={this.props.appState.usertype < Constants.USER_TYPE_ADMIN}
            onFocus={Helper.handleFocus}
            onChange={event => {
              this.handleChangeValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_TWILIO, Constants.SETTINGS_TWILIO_PHONE_NUMBER));
            }}
            onBlur={event => {
              this.handleBlurValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_TWILIO, Constants.SETTINGS_TWILIO_PHONE_NUMBER));
            }}
            value={this.getValue(Constants.SETTINGS_CATEGORY_TWILIO, Constants.SETTINGS_TWILIO_PHONE_NUMBER)}
          />
        </div>
        <div className="settingInputItem ">
          <label className="inputLabel" htmlFor="legalname">
            Legal Name
          </label>
          <input
            type="text"
            name="legalname"
            id="legalname"
            maxLength={50}
            autoComplete="off"
            readOnly={this.props.appState.usertype < Constants.USER_TYPE_ADMIN}
            onFocus={Helper.handleFocus}
            onChange={event => {
              this.handleChangeValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_TWILIO, Constants.SETTINGS_TWILIO_LEGAL_NAME));
            }}
            onBlur={event => {
              this.handleBlurValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_TWILIO, Constants.SETTINGS_TWILIO_LEGAL_NAME));
            }}
            value={this.getValue(Constants.SETTINGS_CATEGORY_TWILIO, Constants.SETTINGS_TWILIO_LEGAL_NAME)}
          />
        </div>
        <div className="settingInputItem ">
          <label className="inputLabel" htmlFor="messagingSid">
            Messaging SID
          </label>
          <input
            type="text"
            name="messagingSid"
            id="messagingSid"
            maxLength={50}
            autoComplete="off"
            readOnly={this.props.appState.usertype < Constants.USER_TYPE_ADMIN}
            onFocus={Helper.handleFocus}
            onChange={event => {
              this.handleChangeValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_TWILIO, Constants.SETTINGS_TWILIO_MESG_SERV_SID));
            }}
            onBlur={event => {
              this.handleBlurValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_TWILIO, Constants.SETTINGS_TWILIO_MESG_SERV_SID));
            }}
            value={this.getValue(Constants.SETTINGS_CATEGORY_TWILIO, Constants.SETTINGS_TWILIO_MESG_SERV_SID)}
          />
        </div>
        {merchant_tc_link}
      </React.Fragment>
    ) : (
      <div className="italic topMargin1em">
        <span className="orange">Elevate your plan by unlocking the power of SMS text messaging!</span>
        <br />
        <br />
        <span>
          Modernize your communications by reaching out to ClerkHound support to add this dynamic feature into your subscription.
          <br />
          Don't miss out on the enhanced communication and engagement opportunities - upgrade now!
        </span>
      </div>
    );

    return (
      <div className="emailSettings">
        <h2 className="component-title settingsH3">Email Settings</h2>
        <div className="italic settinginstructions">
          Specify the name and email address that will appear in the "From" field of emails sent. Optionally, you may specify a BCC email address that
          will receive a copy of all emails sent.
        </div>
        <div className={"italic settinginstructions " + classes}>
          Please note that Yahoo does not allow ClerkHound to send emails on behalf of Yahoo users and therefore @yahoo.com email addresses will be
          rejected.
        </div>
        <div className="settingInputItem ">
          <label className="inputLabel" htmlFor="fromname">
            Name
          </label>
          <input
            type="text"
            name="fromname"
            id="fromname"
            maxLength={50}
            autoComplete="off"
            onFocus={Helper.handleFocus}
            onChange={event => {
              this.handleChangeValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_FROM_NAME));
            }}
            onBlur={event => {
              this.handleBlurValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_FROM_NAME));
            }}
            value={this.getValue(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_FROM_NAME)}
          />
        </div>

        <div className="settingInputItem ">
          <label className="inputLabel" htmlFor="fromaddress">
            Email Address
          </label>
          <input
            type="text"
            name="fromaddress"
            id="fromaddress"
            className={classes}
            maxLength={50}
            autoComplete="off"
            onFocus={Helper.handleFocus}
            onChange={event => {
              this.handleChangeValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_FROM_ADDRESS));
            }}
            onBlur={event => {
              this.handleBlurValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_FROM_ADDRESS), "email", true, () => {
                // Note: Changing this "from email" setting will automatically set the verification flag to false (db_setting.py)
                // So we call getCheckEmailVerification(), which will check the status, update it in the database, and return an updated status code
                // 200 = verified, 202 = not verified, 404 = never tried to verify
                this.getCheckEmailVerification(response => {
                  let status = Constants.FALSE;
                  if (response.status === 200) {
                    status = Constants.TRUE;
                  } else if (response.status === 202) {
                    status = Constants.MAYBE;
                  }
                  // Now, based on the response, update state
                  this.setState(prevState => ({
                    settings: prevState.settings.map(item => {
                      if (item.category === Constants.SETTINGS_CATEGORY_CLIENT && item.description === Constants.SETTINGS_FROM_VERIFIED) {
                        item.value = status;
                      }
                      return item;
                    }),
                  }));
                });
              });
            }}
            value={this.getValue(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_FROM_ADDRESS)}
          />
        </div>
        {verifyEmailAddress}
        <div className="settingInputItem ">
          <label className="inputLabel" htmlFor="bccaddress">
            BCC Address
          </label>
          <input
            type="text"
            name="bccaddress"
            id="bccaddress"
            maxLength={50}
            autoComplete="off"
            onFocus={Helper.handleFocus}
            onChange={event => {
              this.handleChangeValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_BCC_ADDRESS));
            }}
            onBlur={event => {
              this.handleBlurValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_BCC_ADDRESS));
            }}
            value={this.getValue(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_BCC_ADDRESS)}
          />
        </div>
        <h2 className="component-title settingsH3 topMargin1em">Text Settings</h2>
        {smsSettings}
      </div>
    );
  };

  renderAdmin = () => {
    let loadButton = "";
    let newButton = "";
    if (!this.state.globalSettings) {
      loadButton = (
        <div className="settingInputItem">
          <button
            className="action-button green-button"
            onClick={() => {
              this.handleGetGlobalSettings();
            }}
          >
            Load Global Settings
          </button>
        </div>
      );
    } else {
      newButton = (
        <div className="settingInputItem">
          <button
            className="action-button green-button marginBottom1em"
            onClick={() => {
              this.handleAddGlobalSetting();
            }}
          >
            New
          </button>
        </div>
      );
    }
    let globalSettings = this.state.globalSettings?.map(setting => {
      return (
        <React.Fragment key={setting.uuid}>
          <div>
            <FontAwesomeIcon
              icon={faTrash}
              className="deleteIcon leftMarginSmall ghost"
              onClick={() => this.handleDeleteGlobalSetting(setting.uuid)}
            />
          </div>
          <div>{setting.category}</div>
          <div>{setting.description}</div>
          <div className="areaInputItem">
            <FlexInput
              type="text"
              pencilEdit={true}
              name="globalValue"
              id="globalValue"
              datatestid="Global Setting Value"
              autoComplete="off"
              maxLength={255}
              inline={true}
              className="desktop-inline"
              onChange={event => {
                this.setState(prevState => ({
                  globalSettings: prevState.globalSettings.map(item => {
                    if (item.uuid === setting.uuid) {
                      item.value = event.target.value;
                    }
                    return item;
                  }),
                }));
              }}
              onFocus={Helper.handleFocus}
              onBlur={event => {
                this.handleBlurGlobalSetting(event, setting.uuid, event.target.value);
              }}
              value={setting.value}
              renderedValue={setting.value}
            />
          </div>
          {/* <div>&nbsp;</div> */}
        </React.Fragment>
      );
    });
    let deletedSettings = this.state.deletedSettings?.map(setting => {
      return (
        <React.Fragment key={setting.uuid}>
          <div>{setting.category}</div>
          <div>{setting.description}</div>
          <div>{setting.value}</div>
          <div className="justifySelfStart">
            <span
              title="Undelete Global Setting"
              className="action-button brown-button"
              onClick={() => {
                this.handleUndeleteGlobalSetting(setting);
              }}
            >
              Undelete
            </span>
          </div>
        </React.Fragment>
      );
    });
    globalSettings = (
      <div className="globalSettings">
        {globalSettings}
        {deletedSettings}
      </div>
    );

    return (
      <div className="displaySettings">
        <h2 className="component-title settingsH3">Administrative Settings</h2>
        <div className="italic settinginstructions">If you're not Russell or Laura, what are you doing here?</div>
        {loadButton}
        {newButton}
        {globalSettings}
      </div>
    );
  };

  renderDisplaySettings = () => {
    return (
      <div className="displaySettings">
        <h2 className="component-title settingsH3">Display Settings</h2>
        <div className="italic settinginstructions">Specify the number of rows displayed for Lists and Searches.</div>

        <div className="settingInputItem ">
          <label className="inputLabel" htmlFor="listrows">
            Lists results
          </label>
          <input
            type="number"
            name="listrows"
            id="listrows"
            // maxLength={2}
            min={10}
            max={25}
            className="inputNumber"
            autoComplete="off"
            onFocus={Helper.handleFocus}
            onChange={event => {
              this.handleChangeValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_DISPLAY, Constants.SETTINGS_LIST_ROWS));
            }}
            onBlur={event => {
              this.handleBlurValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_DISPLAY, Constants.SETTINGS_LIST_ROWS));
            }}
            value={this.getValue(Constants.SETTINGS_CATEGORY_DISPLAY, Constants.SETTINGS_LIST_ROWS)}
          />
        </div>

        <div className="settingInputItem">
          <label className="inputLabel" htmlFor="resultrows">
            Search results
          </label>
          <input
            type="number"
            name="resultrows"
            id="resultrows"
            // maxLength={2}
            min={3}
            max={10}
            className="inputNumber"
            autoComplete="off"
            onFocus={Helper.handleFocus}
            onChange={event => {
              this.handleChangeValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_DISPLAY, Constants.SETTINGS_FOLDER_ROWS));
            }}
            onBlur={event => {
              this.handleBlurValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_DISPLAY, Constants.SETTINGS_FOLDER_ROWS));
            }}
            value={this.getValue(Constants.SETTINGS_CATEGORY_DISPLAY, Constants.SETTINGS_FOLDER_ROWS)}
          />
        </div>
      </div>
    );
  };

  renderRepairSettings = () => {
    return (
      <div className="repairSettings">
        <h2 className="component-title settingsH3">Service Settings</h2>
        <div className="italic settinginstructions">Specify the service families and designate employees as service technicians.</div>
        <div className="repairGrid">
          {this.renderRepairFamiliesSettings()}
          {this.renderRepairTechnicianSettings()}
        </div>
      </div>
    );
  };

  renderRepairFamiliesSettings = () => {
    const cols = [
      {
        name: "value",
        type: "text",
        placeholder: "Family name",
        maxLength: 25,
        size: 25,
        handleBlur: this.handleBlurValue,
        handleChange: this.handleChangeValue,
      },
    ];
    const items = this.getValues(Constants.SETTINGS_CATEGORY_REPAIR, Constants.SETTINGS_FAMILIES);
    return (
      <div>
        <h3 className="settingsH3">Families</h3>
        <div className="italic settinginstructions">Examples: Band/Orchestra, Guitars, etc</div>
        <SettingsItemList
          appState={this.props.appState}
          cols={cols}
          // enableRowReordering={true}
          items={items}
          handleAddSetting={() => {
            this.handleAddSetting(Constants.SETTINGS_CATEGORY_REPAIR, Constants.SETTINGS_FAMILIES);
          }}
          handleDeleteSetting={this.handleDeleteSetting}
          handleDragStart={this.handleDragStart}
          handleDragOver={this.handleDragOver}
          handleDragEnd={this.handleDragEnd}
          handleDrop={this.handleDrop}
          renderTable={true}
          showOverlay={this.props.showOverlay}
          type={"repairfamilies"}
        />
      </div>
    );
  };

  renderRepairTechnicianSettings = () => {
    const cols = [
      {
        name: "value",
        type: "text",
        readonly: true,
        isUser: true,
        size: 25,
        handleBlur: this.handleBlurValue,
        handleChange: this.handleChangeSelect,
        select: true,
      },
    ];
    const items = this.getValues(Constants.SETTINGS_CATEGORY_REPAIR, Constants.SETTINGS_TECHNICIAN);
    return (
      <div>
        <div>
          <h3 className="settingsH3">Technicians</h3>
          <div className="italic settinginstructions">User must exist in "Employee Logins" before assigning them as a technician.</div>
          <SettingsItemList
            appState={this.props.appState}
            cols={cols}
            // enableRowReordering={true}
            getValue={this.getValue}
            handleAddSetting={this.handleAddTechnician}
            handleDeleteSetting={this.handleDeleteSetting}
            handleDragStart={this.handleDragStart}
            handleDragOver={this.handleDragOver}
            handleDragEnd={this.handleDragEnd}
            handleDrop={this.handleDrop}
            items={items}
            newItemAttribute="fullname"
            newItemDefault={""}
            newItemOptions={this.state.technicians ?? []}
            renderTable={true}
            showOverlay={this.props.showOverlay}
            users={this.state.users ?? []}
            type={"technicians"}
          />
        </div>
      </div>
    );
  };

  renderSalesTaxSettings = () => {
    const cols = [
      {
        name: "description",
        type: "text",
        placeholder: "Description",
        maxLength: 25,
        size: 10,
        handleBlur: this.handleBlurDescription,
        handleChange: this.handleChangeDescription,
      },
      {
        name: "value",
        classes: "salesTaxValue",
        type: "number",
        placeholder: "Ex.- 0.01",
        step: 0.01,
        min: 0.0,
        max: 0.3,
        handleBlur: this.handleBlurValue,
        handleChange: this.handleChangeValue,
      },
    ];
    const items = this.getValues(Constants.SETTINGS_CATEGORY_SALES_TAXES);
    let taxRate =
      items.reduce((total, item) => {
        return total + parseFloat(item.value ? item.value : 0);
      }, 0) * 100;
    // Round the taxRate to 3 decimal places
    taxRate = Math.round(taxRate * 1000) / 1000;
    const taxWarning = taxRate > 30 ? <span className="highlight">Are you sure your taxes are this high?</span> : "";
    return (
      <div className="salesTaxSettings">
        <h2 className="component-title settingsH3">Sales Tax Rates</h2>
        <div className="italic settinginstructions">
          Enter your location's sales tax rate(s). Sales tax can be entered as a single rate or can be broken down into its component parts, for
          example, State, Local, SPLOST, etc.
        </div>
        <div className="settingsTaxRateContainer">
          <span className="settingsTaxRate white">Tax Rate: {taxRate}%</span>
          {taxWarning}
        </div>
        <div>
          <SettingsItemList
            appState={this.props.appState}
            cols={cols}
            // enableRowReordering={true}
            handleAddSetting={() => {
              this.handleAddSetting(Constants.SETTINGS_CATEGORY_SALES_TAXES, "");
            }}
            handleDeleteSetting={this.handleDeleteSetting}
            handleDragStart={this.handleDragStart}
            handleDragOver={this.handleDragOver}
            handleDragEnd={this.handleDragEnd}
            handleDrop={this.handleDrop}
            items={items}
            renderTable={true}
            showOverlay={this.props.showOverlay}
            type="salesTax"
          />
        </div>
      </div>
    );
  };

  renderGiftCardSettings = () => {
    const cols = [
      {
        name: "value",
        type: "text",
        placeholder: "SKU/UPC name",
        maxLength: 50,
        size: 25,
        handleBlur: (event, uuid, rowindex) => {
          this.handleBlurValue(event, uuid, "sku_upc");
        },
        handleChange: this.handleChangeValue,
      },
    ];
    const items = this.getValues(Constants.SETTINGS_CATEGORY_GIFT_CARD, Constants.SETTINGS_SKU);
    return (
      <div className="giftCardSettings">
        <div>
          <h2 className="settingsH3">Gift Card SKU/UPC</h2>
          <div className="italic settinginstructions">A gift card "product" must exist before adding its store sku/upc to settings.</div>
          <SettingsItemList
            appState={this.props.appState}
            cols={cols}
            // enableRowReordering={true}
            handleAddSetting={() => {
              this.handleAddSetting(Constants.SETTINGS_CATEGORY_GIFT_CARD, Constants.SETTINGS_SKU);
            }}
            handleDeleteSetting={this.handleDeleteSetting}
            handleDragStart={this.handleDragStart}
            handleDragOver={this.handleDragOver}
            handleDragEnd={this.handleDragEnd}
            handleDrop={this.handleDrop}
            items={items}
            renderTable={true}
            showOverlay={this.props.showOverlay}
            type="giftcards"
          />
        </div>
      </div>
    );
  };

  renderCustomDataSettings = () => {
    if (!this.props.appState.features.includes(Constants.FEATURE_BILLING)) {
      return "";
    }
    const cols = [
      {
        name: "description",
        type: "text",
        placeholder: "Custom data field name",
        maxLength: 64,
        size: 25,
        handleBlur: (event, uuid, rowindex) => {
          this.handleBlurDescription(event, uuid);
        },
        handleChange: this.handleChangeDescription,
      },
    ];
    const items = this.getValues(Constants.SETTINGS_CATEGORY_CUSTOM_FIELDS);
    return (
      <div className="customFieldSettings">
        <div>
          <h2 className="settingsH3">Billing Custom Fields Template</h2>
          <div className="italic settinginstructions">
            Define custom field labels for billing subscriptions. Reorder fields by dragging and dropping. Deleting a label will hide associated data
            from billing display.
          </div>
          <SettingsItemList
            appState={this.props.appState}
            cols={cols}
            enableRowReordering={true}
            handleAddSetting={() => {
              const index = items.length + 1 + "";
              this.handleAddSetting(Constants.SETTINGS_CATEGORY_CUSTOM_FIELDS, "", index, true);
            }}
            handleDeleteSetting={this.handleDeleteSetting}
            handleDragStart={this.handleDragStart}
            handleDragOver={this.handleDragOver}
            handleDragEnd={this.handleDragEnd}
            handleDrop={this.handleDrop}
            items={items}
            renderTable={true}
            showOverlay={this.props.showOverlay}
            type="customField"
          />
        </div>
      </div>
    );
  };

  renderCreditCardSettings = () => {
    const merchant_id = this.getValue(Constants.SETTINGS_CATEGORY_MAAST, Constants.SETTINGS_MAAST_MERCHANT_ID, false);
    const isMerchantDisabled = merchant_id === "" || merchant_id === null;
    const isCloudKeyEmpty = this.getValue(Constants.SETTINGS_CATEGORY_HANDPOINT, Constants.SETTINGS_API_KEY_CLOUD, false) === "";
    const merchant_message = isMerchantDisabled ? (
      <React.Fragment>
        <div className="white">Have you completed your MAAST merchant account application?</div>
        <ul>
          <li>
            <span className="white">No.</span> Check your email for the link to your merchant application. If you can't find it, contact support for
            assistance.
          </li>
          <li>
            <span className="white">Yes!</span> Your ClerkHound account will be updated with your merchant information as soon as your application is
            approved.
          </li>
        </ul>
      </React.Fragment>
    ) : (
      ""
    );
    const addValorEPI =
      this.props.appState.usertype === Constants.USER_TYPE_ADMIN ? (
        <span className="action-button green-button addSetting" onClick={this.handleAddValorEPI}>
          Add
        </span>
      ) : (
        ""
      );
    const handpointEnabled = this.getValue(Constants.SETTINGS_CATEGORY_HANDPOINT, Constants.SETTINGS_ENABLED, true);
    const valorEnabled = this.getValue(Constants.SETTINGS_CATEGORY_VALOR, Constants.SETTINGS_ENABLED, true);
    const maastEnabled = this.getValue(Constants.SETTINGS_CATEGORY_MAAST, Constants.SETTINGS_ENABLED, true);
    const defaultManual = this.getValue(Constants.SETTINGS_CATEGORY_MAAST, Constants.SETTINGS_DEFAULT_PARTIAL_MANUAL, true);
    const defaultVault = this.getValue(Constants.SETTINGS_CATEGORY_MAAST, Constants.SETTINGS_DEFAULT_PARTIAL_VAULT, true);
    const handpointTerminals = this.state.terminals?.map(terminal => {
      return (
        <div key={terminal.serial_number}>
          <label className="inputLabel" htmlFor="terminalname">
            {terminal.terminal_type}/{terminal.serial_number}
          </label>
          <input
            type="text"
            name="terminalname"
            id="terminalname"
            placeholder="Terminal name"
            maxLength={255}
            autoComplete="off"
            onFocus={Helper.handleFocus}
            onChange={event => {
              this.setState(prevState => ({
                settings: prevState.settings.map(setting => {
                  if (setting.category === Constants.SETTINGS_CATEGORY_HANDPOINT && setting.description === terminal.serial_number) {
                    setting = {
                      ...setting,
                      value: event.target.value,
                    };
                  }
                  return setting;
                }),
              }));
            }}
            onBlur={event => {
              this.handleBlurValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_HANDPOINT, terminal.serial_number));
            }}
            value={this.getValue(Constants.SETTINGS_CATEGORY_HANDPOINT, terminal.serial_number)}
          />
        </div>
      );
    });
    const valorTerminals = this.state.settings
      .filter(s => s.category === Constants.SETTINGS_CATEGORY_VALOR && s.description === Constants.SETTINGS_VALOR_EPI)
      .map(terminal => {
        const epi =
          this.props.appState.usertype === Constants.USER_TYPE_ADMIN ? (
            <input
              type="text"
              name="terminalepi"
              id="terminalepi"
              placeholder="Terminal EPI"
              maxLength={255}
              autoComplete="off"
              onFocus={Helper.handleFocus}
              onChange={event => {
                this.setState(prevState => ({
                  settings: prevState.settings.map(setting => {
                    // Update the EPI setting value
                    if (setting.uuid === terminal.uuid) {
                      setting = {
                        ...setting,
                        value: event.target.value,
                      };
                    }
                    return setting;
                  }),
                }));
              }}
              onBlur={event => {
                this.handleBlurValue(event, terminal.uuid, "text", true, null, setting => {
                  // If this is a new setting, then add the terminal friendly name setting
                  this.postSetting(
                    {
                      category: Constants.SETTINGS_CATEGORY_VALOR,
                      description: setting.uuid,
                      value: "",
                    },
                    friendlyNameSetting => {
                      // Add the friendly name setting to state
                      this.setState(prevState => ({
                        settings: [...prevState.settings, friendlyNameSetting],
                      }));
                    }
                  );
                  this.postSetting(
                    {
                      category: Constants.SETTINGS_CATEGORY_VALOR_SECURITY,
                      description: setting.uuid,
                      value: "",
                    },
                    appKeySetting => {
                      // Add the app key setting to state
                      this.setState(prevState => ({ settings: [...prevState.settings, appKeySetting] }));
                    }
                  );
                });
              }}
              value={terminal.value}
            />
          ) : (
            <label className="inputLabel" htmlFor="terminalname">
              EPI: {terminal.value}
            </label>
          );
        const trash =
          this.props.appState.usertype === Constants.USER_TYPE_ADMIN ? (
            <FontAwesomeIcon
              icon={faTrash}
              className="deleteSetting"
              onClick={() => this.handleDeleteSetting(terminal.uuid, this.getUUID(Constants.SETTINGS_CATEGORY_VALOR, terminal.uuid))}
            />
          ) : (
            ""
          );
        return (
          <div key={terminal.uuid} className="valorTerminals">
            {epi}
            <input
              type="text"
              name="terminalname"
              id="terminalname"
              placeholder="Terminal name"
              maxLength={255}
              autoComplete="off"
              onFocus={Helper.handleFocus}
              onChange={event => {
                this.setState(prevState => ({
                  settings: prevState.settings.map(setting => {
                    if (setting.category === Constants.SETTINGS_CATEGORY_VALOR && setting.description === terminal.uuid) {
                      setting = {
                        ...setting,
                        value: event.target.value,
                      };
                    }
                    return setting;
                  }),
                }));
              }}
              onBlur={event => {
                this.handleBlurValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_VALOR, terminal.uuid));
              }}
              value={this.getValue(Constants.SETTINGS_CATEGORY_VALOR, terminal.uuid)}
            />
            <input
              type="text"
              name="appkey"
              id="appkey"
              readOnly={this.props.appState.usertype < Constants.USER_TYPE_ADMIN}
              placeholder="App Key"
              maxLength={255}
              autoComplete="off"
              onFocus={Helper.handleFocus}
              onChange={event => {
                this.setState(prevState => ({
                  settings: prevState.settings.map(setting => {
                    if (setting.category === Constants.SETTINGS_CATEGORY_VALOR_SECURITY && setting.description === terminal.uuid) {
                      setting = {
                        ...setting,
                        value: event.target.value,
                      };
                    }
                    return setting;
                  }),
                }));
              }}
              onBlur={event => {
                this.handleBlurValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_VALOR_SECURITY, terminal.uuid));
              }}
              value={this.getValue(Constants.SETTINGS_CATEGORY_VALOR_SECURITY, terminal.uuid)}
            />
            {trash}
          </div>
        );
      });
    const features = this.props.appState.features;
    const readers = !features.includes(Constants.FEATURE_PRODUCTS) ? (
      ""
    ) : (
      <React.Fragment>
        <h3 className="settingsH3">HandPoint Card Reader(s)</h3>
        <div title={isCloudKeyEmpty ? "Enter Cloud Key to enable" : ""}>
          <Switch
            label="Credit card terminal"
            fieldname="handpointenabled"
            fielddisabled={isMerchantDisabled || isCloudKeyEmpty}
            handleChange={event => {
              this.handleChangeSwitch(event, this.getUUID(Constants.SETTINGS_CATEGORY_HANDPOINT, Constants.SETTINGS_ENABLED));
            }}
            checked={handpointEnabled}
            elementid="switch-handpointenabled"
            yes="Enabled"
            no="Disabled"
            switchClass="terminal"
          />
        </div>
        <div>
          <label className="inputLabel" htmlFor="apicloudkey">
            API Cloud Key
          </label>
          <input
            type="text"
            name="apicloudkey"
            id="apicloudkey"
            readOnly={this.props.appState.usertype < Constants.USER_TYPE_ADMIN}
            placeholder="API Cloud Key"
            maxLength={255}
            autoComplete="off"
            onFocus={Helper.handleFocus}
            onChange={event => {
              this.handleChangeValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_HANDPOINT, Constants.SETTINGS_API_KEY_CLOUD));
            }}
            onBlur={event => {
              this.handleBlurValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_HANDPOINT, Constants.SETTINGS_API_KEY_CLOUD), "text", true, () => {
                // If the api cloud key is empty, disable the handpoint terminals
                if (this.getValue(Constants.SETTINGS_CATEGORY_HANDPOINT, Constants.SETTINGS_API_KEY_CLOUD) === "") {
                  this.handleChangeSwitch(
                    { target: { checked: false } },
                    this.getUUID(Constants.SETTINGS_CATEGORY_HANDPOINT, Constants.SETTINGS_ENABLED)
                  );
                }
              });
            }}
            value={this.getValue(Constants.SETTINGS_CATEGORY_HANDPOINT, Constants.SETTINGS_API_KEY_CLOUD)}
          />
        </div>

        <React.Fragment>{handpointTerminals}</React.Fragment>
        <h3 className="settingsH3">Valor Paytech Card Reader(s)</h3>
        <div>
          <Switch
            label="Credit card terminal"
            fieldname="valorenabled"
            fielddisabled={isMerchantDisabled}
            handleChange={event => {
              this.handleChangeSwitch(event, this.getUUID(Constants.SETTINGS_CATEGORY_VALOR, Constants.SETTINGS_ENABLED));
            }}
            checked={valorEnabled}
            elementid="switch-valorenabled"
            yes="Enabled"
            no="Disabled"
            switchClass="terminal"
          />
        </div>
        <div>
          <label className="inputLabel" htmlFor="appid">
            App ID
          </label>
          <input
            type="text"
            name="appid"
            id="appid"
            readOnly={this.props.appState.usertype < Constants.USER_TYPE_ADMIN}
            placeholder="App ID"
            maxLength={255}
            autoComplete="off"
            onFocus={Helper.handleFocus}
            onChange={event => {
              this.handleChangeValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_VALOR_SECURITY, Constants.SETTINGS_VALOR_APP_ID));
            }}
            onBlur={event => {
              this.handleBlurValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_VALOR_SECURITY, Constants.SETTINGS_VALOR_APP_ID));
            }}
            value={this.getValue(Constants.SETTINGS_CATEGORY_VALOR_SECURITY, Constants.SETTINGS_VALOR_APP_ID)}
          />
        </div>
        {addValorEPI}
        <React.Fragment>{valorTerminals}</React.Fragment>
      </React.Fragment>
    );

    return (
      <div className="creditCardSettings">
        <h2 className="component-title settingsH3">Credit Card</h2>
        <div className="italic settinginstructions">Clerkhound supports both card reader and manual credit card entry.</div>
        {merchant_message}
        <div>
          <label className="inputLabel" htmlFor="merchant_id">
            Merchant ID
          </label>
          <input
            type="text"
            name="merchant_id"
            id="merchant_id"
            readOnly={this.props.appState.usertype < Constants.USER_TYPE_ADMIN}
            placeholder="Merchant ID"
            maxLength={255}
            autoComplete="off"
            onFocus={Helper.handleFocus}
            onChange={event => {
              this.handleChangeValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_MAAST, Constants.SETTINGS_MAAST_MERCHANT_ID));
            }}
            onBlur={event => {
              this.handleBlurValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_MAAST, Constants.SETTINGS_MAAST_MERCHANT_ID));
            }}
            value={this.getValue(Constants.SETTINGS_CATEGORY_MAAST, Constants.SETTINGS_MAAST_MERCHANT_ID)}
          />
        </div>
        {readers}
        <h3 className="settingsH3">Maast Manual Credit Card Entry</h3>
        <div>
          <Switch
            label="Manual entry"
            fieldname="maastenabled"
            fielddisabled={isMerchantDisabled}
            handleChange={event => {
              this.handleChangeSwitch(event, this.getUUID(Constants.SETTINGS_CATEGORY_MAAST, Constants.SETTINGS_ENABLED));
            }}
            checked={maastEnabled}
            elementid="switch-maastenabled"
            yes="Enabled"
            no="Disabled"
            switchClass="enabled"
          />
          <h3 className="settingsH3">Maast Partial Approval Defaults</h3>
          <div className="italic settinginstructions">Specify the default behavior for the "partial approval" switches during credit payments.</div>
          <Switch
            label="Manual Card Entry"
            fieldname="defaultpartialmanual"
            fielddisabled={isMerchantDisabled}
            handleChange={event => {
              this.handleChangeSwitch(event, this.getUUID(Constants.SETTINGS_CATEGORY_MAAST, Constants.SETTINGS_DEFAULT_PARTIAL_MANUAL));
            }}
            checked={defaultManual}
            elementid="switch-defaultManual"
            yes="Approve"
            no="Decline"
            switchClass="enabled"
          />
          <Switch
            label="Vaulted Payments"
            fieldname="defaultpartialvault"
            fielddisabled={isMerchantDisabled}
            handleChange={event => {
              this.handleChangeSwitch(event, this.getUUID(Constants.SETTINGS_CATEGORY_MAAST, Constants.SETTINGS_DEFAULT_PARTIAL_VAULT));
            }}
            checked={defaultVault}
            elementid="switch-defaultVault"
            yes="Approve"
            no="Decline"
            switchClass="enabled"
          />
        </div>
      </div>
    );
  };

  renderSubdomainInput = disable => {
    return (
      <input
        type="text"
        name="subdomain"
        id="subdomain"
        disabled={disable}
        placeholder="Merchant subdomain"
        className="subdomainInput"
        maxLength={255}
        autoComplete="off"
        onFocus={disable ? () => {} : Helper.handleFocus}
        onChange={disable ? () => {} : this.handleChangeClient}
        onBlur={disable ? () => {} : this.handleBlurClient}
        value={this.state.client?.subdomain}
      />
    );
  };

  renderThirdPartySettings = () => {
    let cartloom = "";
    // Only draw something if state is populated
    if (this.props.appState?.features && this.props.appState?.usertype) {
      // Cartloom is a merchant feature
      if (this.props.appState.features.includes(Constants.FEATURE_CARTLOOM)) {
        let sellerid = this.getValue(Constants.SETTINGS_CATEGORY_CARTLOOM, Constants.SETTINGS_SELLERID);
        let publishButton = "";
        // If there is no seller ID, then we need to have a Publish to Cartloom button
        if (!sellerid) {
          sellerid = "";
          publishButton = (
            <span className="action-button green-button addSetting" onClick={this.handleAddCartloom}>
              Publish
            </span>
          );
        }
        // Admin User
        if (this.props.appState.usertype === Constants.USER_TYPE_ADMIN) {
          cartloom = (
            <React.Fragment>
              <div className="settingInputItem">
                <label className="inputLabel" htmlFor="subdomain">
                  Cart ID
                </label>
                {this.renderSubdomainInput(false)}
              </div>
              {this.renderSellerIDInput(sellerid)}

              {publishButton}
            </React.Fragment>
          );
        }
        // Non-admin user
        else {
          cartloom = (
            <React.Fragment>
              <div className="settingInputItem">
                <label className="inputLabel" htmlFor="cartID">
                  Cart ID
                </label>
                {this.renderSubdomainInput(true)}
              </div>
              {this.renderSellerIDInput(sellerid)}
            </React.Fragment>
          );
        }
      }
      // Cartloom is NOT a merchant feature
      else {
        cartloom = (
          <div>
            Upgrade your ClerkHound subscription to <span className="highlight">ClerkHound Pro + E-Commerce</span> to enable your Cartloom online
            store!
          </div>
        );
      }
    }
    return (
      <div className="thirdPartySettings">
        <h2 className="component-title settingsH3">Third Party Integration</h2>
        <div className="italic settinginstructions">Integrate ClerkHound with third-party applications.</div>
        <div>
          <h3 className="settingsH3">Reverb API key</h3>
          <div className="italic settinginstructions">
            Must be a customer of Reverb. Reverb Personal Access Token must have the following OAuth scopes selected: public, read_listings,
            write_listings, read_profile, read_orders and write_orders.
          </div>
          <input
            type="text"
            className="reverbSettings"
            name="reverbapikey"
            id="reverbapikey"
            placeholder="Reverb API key"
            maxLength={255}
            autoComplete="off"
            onFocus={Helper.handleFocus}
            onChange={event => {
              this.handleChangeValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_REVERB, Constants.SETTINGS_API_KEY));
            }}
            onBlur={event => {
              this.handleBlurValue(event, this.getUUID(Constants.SETTINGS_CATEGORY_REVERB, Constants.SETTINGS_API_KEY));
            }}
            value={this.getValue(Constants.SETTINGS_CATEGORY_REVERB, Constants.SETTINGS_API_KEY)}
          />
          <h3 className="settingsH3">CartLoom</h3>
          <div className="italic settinginstructions">Information-only settings used for CartLoom integration.</div>
          {cartloom}
        </div>
      </div>
    );
  };

  renderSellerIDInput(sellerid) {
    return (
      <div className="settingInputItem">
        <label className="inputLabel" htmlFor="sellerID">
          Seller ID
        </label>
        <input type="text" name="sellerID" id="sellerID" placeholder="Seller ID" maxLength={50} autoComplete="off" readOnly={true} value={sellerid} />
      </div>
    );
  }

  renderUsersSettings = () => {
    const cols = [
      {
        name: "fullname",
        classes: "fullname",
        type: "text",
        placeholder: "Full name",
        maxLength: 255,
        size: 30,
        handleBlur: this.handleBlurFullName,
        handleChange: this.handleChangeFullName,
      },
      {
        name: "username",
        classes: "email",
        type: "text",
        placeholder: "Email address",
        maxLength: 255,
        size: 30,
        handleBlur: this.handleBlurUserName,
        handleChange: this.handleChangeUserName,
      },
      {
        name: "usertype",
        type: "select",
        handleChange: this.handleChangeUserRole,
        select: true,
        editableAfterSave: true,
      },
      {
        name: "replytoallowed",
        type: "checkbox",
        handleChange: this.handleChangeReplyToAllowed,
      },
    ];
    let users = Helper.deepCopy(this.state.users ?? []);
    // Add a flag to each user indicating if they are allowed to reply to emails
    users = users.map(user => {
      user.replytoallowed = this.getUUID(Constants.SETTINGS_CATEGORY_MAIL, Constants.SETTINGS_REPLY_TO_ALLOWED, user.uuid) ? true : false;
      user.editable = () => {
        return user.usertype !== Constants.USER_TYPE_ADMIN;
      };
      return user;
    });
    // Filter out (hide) admin users if not an admin user
    if (this.props.appState.usertype !== Constants.USER_TYPE_ADMIN) {
      users = users.filter(user => {
        return user.usertype !== Constants.USER_TYPE_ADMIN;
      });
    }
    return (
      <React.Fragment>
        <div className="usersSettings ">
          <h2 className="component-title settingsH3">Employee Logins</h2>
          <div className="italic settinginstructions white">
            As soon as a user is added to Employee Logins, a link is sent to them via email to set up their account.
            <br />
            <span className="highlight">For security purposes, this link will expire after 24 hours.</span> If their link expires, direct them to use
            the password reset function on the login screen.
          </div>
          <div className="italic settinginstructions">
            <span className="highlight">Employee</span> - Access to basic point-of-sale features.
            <br />
            <span className="highlight">Manager</span> - All point-of-sale features plus editing order dates, editing timesheets of other employees,
            adding payment methods to the card vault, paying invoices using vaulted payment methods, updating product listings from invoices, and
            viewing/editing recurring billing information.
            <br />
            <span className="highlight">Owner</span> - All features listed above, plus editing settings, deleting closed invoices, updating closed
            invoice statuses, and importing/exporting data.
          </div>
          <div className="italic settinginstructions">
            The <span className="highlight">Reply-To Allowed</span> checkbox allows the user to set themselves as the "reply to" address in emails
            sent from ClerkHound.
          </div>
          <div>
            <SettingsItemList
              appState={this.props.appState}
              cols={cols}
              // enableRowReordering={true}
              handleAddSetting={this.handleAddUser}
              handleDeleteSetting={this.handleDeleteUser}
              handleDragStart={this.handleDragStart}
              handleDragOver={this.handleDragOver}
              handleDragEnd={this.handleDragEnd}
              handleDrop={this.handleDrop}
              items={users}
              newItemOptions={[
                { label: "Owner", uuid: Constants.USER_TYPE_OWNER },
                { label: "Manager", uuid: Constants.USER_TYPE_MANAGER },
                { label: "Employee", uuid: Constants.USER_TYPE_CLERK },
              ]}
              newItemDefault={null}
              newItemAttribute="label"
              renderTable={false}
              showOverlay={this.props.showOverlay}
              type="users"
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  handleAddEnterprise = () => {
    this.props.showOverlay({
      type: Constants.OVERLAY_INPUT_BOX,
      text: "Enterprise Name",
      maxLength: 255,
      okButtonLabel: "Create",
      callback: (response, key, enterprisename) => {
        if (response === Constants.OVERLAY_RESPONSE_OK) {
          this.putClient("enterprisename", enterprisename, Constants.ACTION_ADD_ENTERPRISE);
        } else {
          // Cancelled
          this.props.hideOverlay();
        }
      },
    });
  };

  handleClearEnterprise = () => {
    this.putClient("enterprisename", "");
  };

  handleSetEnterprise = enterprisename => {
    this.putClient("enterprisename", enterprisename);
  };

  handleAddCartloom = () => {
    const merchantuuid = this.state.client?.clientuuid;
    if (merchantuuid) {
      this.postEcommerce(merchantuuid, Constants.CREATE_ACCOUNT);
    }
  };

  handleDropPhoto = event => {
    event.preventDefault();
    let files = event.dataTransfer.files;
    const file = files[0];
    const reader = new FileReader();
    reader.onload = e => {
      this.handleOnLoadPhoto(e, file);
    };
    reader.readAsDataURL(file);
  };

  handleOnLoadPhoto = (e, file) => {
    // Check the file type is svg
    if (file.type !== "image/svg+xml") {
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "The file must be an SVG image.",
      });
      return;
    }
    // Check aspect ratio
    const img = new Image();
    img.src = e.target.result;
    img.onload = () => {
      const width = img.width;
      const height = img.height;
      const aspectRatio = width / height;
      if (aspectRatio < 0.75 || aspectRatio > 1.3) {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "The aspect ratio of the image must be between 0.75 and 1.3.",
        });
        return;
      }
      this.props.postPhotoBinary(
        "clientlogo",
        Constants.CLIENT,
        e.target.result,
        file.name,
        file.size,
        file.type || Helper.getFileType(file.name),
        this.handleAfterAddLogo
      );
    };
  };

  handleAfterAddLogo = (result, photouuid, photourl) => {
    if (result) {
      // Display the new logo
      this.setState(prevState => ({
        settings: prevState.settings.map(setting => {
          if (setting.category === Constants.SETTINGS_CATEGORY_CLIENT && setting.description === Constants.SETTINGS_LOGO) {
            setting.value = photouuid;
          }
          return setting;
        }),
      }));
    }
  };

  handleChangeFullName = (event, uuid) => {
    this.setState(prevState => {
      const users = prevState.users.map(user => {
        if (user.uuid === uuid) {
          user.fullname = event.target.value;
        }
        return user;
      });
      return { users: users };
    });
  };

  handleChangeUserName = (event, uuid) => {
    this.setState(prevState => {
      const users = prevState.users.map(user => {
        if (user.uuid === uuid) {
          user.username = event.target.value;
        }
        return user;
      });
      return { users: users };
    });
  };

  handleChangeReplyToAllowed = (event, uuid) => {
    const replyToAllowed = event.target.checked;
    if (replyToAllowed) {
      // Add a new setting value indicating the user is allowed set themselves as the "reply to" in emails
      const setting = {
        category: Constants.SETTINGS_CATEGORY_MAIL,
        description: Constants.SETTINGS_REPLY_TO_ALLOWED,
        value: uuid,
        isNew: true,
      };
      this.setState(
        prevState => {
          const settings = [...prevState.settings, setting];
          return { settings: settings };
        },
        () => {
          this.postSetting(setting);
        }
      );
    } else {
      // Remove the setting for this user
      const settinguuid = this.getUUID(Constants.SETTINGS_CATEGORY_MAIL, Constants.SETTINGS_REPLY_TO_ALLOWED, uuid);
      this.deleteSetting(settinguuid);
    }
  };

  handleChangeUserRole = (event, uuid) => {
    const prev = event.target.getAttribute(Constants.ATTR_DATA_VALUE);
    if (prev === event.target.value) {
      return;
    }
    event.target.removeAttribute(Constants.ATTR_DATA_VALUE);

    const user = this.state.users.find(user => user.uuid === uuid);
    if (user) {
      const usertype = event.target.value;
      // If the role changed and the user already exists in the database, update the user's role
      if (usertype !== prev && !user.isNew) {
        this.putUser({ useruuid: user.uuid, usertype: usertype }, () => {
          this.updateUserTypeInState(uuid, usertype);
        });
      } else if (usertype !== prev && user.isNew && user.fullname && user.username && user.usertype) {
        // If the full name changed, the user is new, and the username is valid, create the user
        this.postUser(user.uuid, {
          fullname: user.fullname,
          username: user.username,
          usertype: usertype,
        });
      } else if (usertype !== prev && user.isNew) {
        this.updateUserTypeInState(uuid, usertype);
      }
    }
  };

  handleChangeClient = event => {
    this.setState(prevState => ({
      client: {
        ...prevState.client,
        [event.target.name]: event.target.value,
      },
    }));
  };

  updateUserTypeInState = (uuid, usertype) => {
    this.setState(prevState => ({
      users: prevState.users.map(user => {
        if (user.uuid === uuid) {
          user.usertype = usertype;
        }
        return user;
      }),
    }));
  };

  handleAddValorEPI = () => {
    this.handleAddSetting(Constants.SETTINGS_CATEGORY_VALOR, Constants.SETTINGS_VALOR_EPI);
  };

  handleBlurFullName = (event, uuid) => {
    const prev = event.target.getAttribute(Constants.ATTR_DATA_VALUE);
    if (prev === event.target.value) {
      return;
    }
    event.target.removeAttribute(Constants.ATTR_DATA_VALUE);

    const user = this.state.users.find(user => user.uuid === uuid);
    if (user) {
      const fullname = event.target.value;
      // If the name changed and we have a valid username, update the user
      if (fullname !== prev && !user.isNew) {
        this.putUser({ useruuid: user.uuid, fullname: fullname });
      } else if (fullname !== prev && user.isNew && user.fullname && user.username && user.usertype) {
        // If the full name changed, the user is new, and the username is valid, create the user
        this.postUser(user.uuid, {
          fullname: fullname,
          username: user.username,
          usertype: user.usertype,
        });
      }
    }
  };

  handleBlurUserName = (event, uuid, rowindex) => {
    const prev = event.target.getAttribute(Constants.ATTR_DATA_VALUE);
    if (prev === event.target.value) {
      return;
    }
    event.target.removeAttribute(Constants.ATTR_DATA_VALUE);
    const value = event.target.value;

    // Validate email values
    if (value) {
      if (!value.match(Constants.REGEX_EMAIL)) {
        this.setState(prevState => ({
          users: prevState.users.map(user => {
            return user.useruuid === uuid ? { ...user, username: prev } : user;
          }),
        }));
        this.props.showOverlay({
          text: "Invalid email address",
          type: Constants.OVERLAY_MESSAGE,
        });
        return;
      }
    }

    const user = this.state.users.find(user => user.uuid === uuid);
    if (user) {
      const username = event.target.value;
      if (username !== prev && !user.isNew && !username) {
        // Disallow blank usernames for existing users
        this.setState(prevState => ({
          users: prevState.users.map(user => {
            return user.useruuid === uuid ? { ...user, username: prev } : user;
          }),
        }));
        this.props.showOverlay({
          text: "Username cannot be blank",
          type: Constants.OVERLAY_MESSAGE,
        });
      } else if (username !== prev && !user.isNew) {
        this.putUser({ useruuid: user.uuid, username: username });
      } else if (username !== prev && user.isNew && user.fullname && user.username && user.usertype) {
        // If the username changed, the user is new, and the full name is valid, create the user
        this.postUser(user.uuid, {
          fullname: user.fullname,
          username: username,
          usertype: user.usertype,
        });
      }
    }
  };

  handleDeleteUser = uuid => {
    // If the setting is new, just remove it from the list
    if (this.state.users.find(user => user.uuid === uuid).isNew) {
      this.setState(prevState => ({
        users: prevState.users.filter(user => user.uuid !== uuid),
      }));
    } else {
      this.props.showOverlay({
        callback: this.maybeDeleteUser,
        key: uuid,
        text: "Are you sure you want to delete this user?",
        type: Constants.OVERLAY_QUESTION,
        value: null,
      });
    }
  };

  handleAddUser = () => {
    this.setState(prevState => ({
      users: [
        {
          isNew: true,
          useruuid: prevState.users.length + 1,
          uuid: prevState.users.length + 1,
          fullname: "",
          username: "",
          usertype: 1,
        },
      ].concat(prevState.users),
    }));
  };

  handleAddSetting = (category, description, value = "", appendToEnd = false) => {
    if (appendToEnd) {
      this.setState(prevState => ({
        settings: prevState.settings.concat([
          {
            isNew: true,
            settinguuid: prevState.settings.length + 1,
            uuid: prevState.settings.length + 1,
            category: category,
            description: description,
            value: value,
          },
        ]),
      }));
    } else {
      this.setState(prevState => ({
        settings: [
          {
            isNew: true,
            settinguuid: prevState.settings.length + 1,
            uuid: prevState.settings.length + 1,
            category: category,
            description: description,
            value: value,
          },
        ].concat(prevState.settings),
      }));
    }
  };

  handleAddTechnician = () => {
    // Filter out existing technicians
    const technicians = this.state.users.filter(user => {
      const tech = this.getValues(Constants.SETTINGS_CATEGORY_REPAIR, Constants.SETTINGS_TECHNICIAN).find(tech => tech.value === user.uuid);
      return !tech;
    });
    this.setState({ technicians: technicians }, () => {
      this.handleAddSetting(Constants.SETTINGS_CATEGORY_REPAIR, Constants.SETTINGS_TECHNICIAN);
    });
  };

  handleChangeValue = (event, uuid) => {
    this.setState(
      prevState => ({
        settings: prevState.settings.map(setting => {
          if (setting.settinguuid === uuid) {
            setting.value = event.target.value;
          }
          return setting;
        }),
      }),
      () => {
        // Timezone is a <select>
        if (event.target.type === "select-one") {
          this.putSetting(uuid, null, event.target.value);
        }
      }
    );
  };

  handleChangeSwitch = (event, uuid) => {
    const value = event.target.checked ? "true" : "false";
    this.setState(
      prevState => ({
        settings: prevState.settings.map(setting => {
          if (setting.settinguuid === uuid) {
            setting.value = value;
          }
          return setting;
        }),
      }),
      () => {
        this.putSetting(uuid, null, value);
      }
    );
  };

  handleChangeMode = (event, uuid) => {
    const value = event.target.checked ? "prod" : "test";
    this.setState(
      prevState => ({
        settings: prevState.settings.map(setting => {
          if (setting.settinguuid === uuid) {
            setting.value = value;
          }
          return setting;
        }),
      }),
      () => {
        this.putSetting(uuid, null, value);
      }
    );
  };

  handleChangeSelect = (event, uuid) => {
    const techuuid = event.target.value;
    this.setState(
      prevState => ({
        settings: prevState.settings.map(setting => {
          if (setting.settinguuid === uuid) {
            setting.value = techuuid;
          }
          return setting;
        }),
      }),
      () => {
        // New technician is a <select> which switches to an <input> after selection
        if (event.target.type === "select-one") {
          const setting = {
            settinguuid: uuid,
            category: Constants.SETTINGS_CATEGORY_REPAIR,
            description: Constants.SETTINGS_TECHNICIAN,
            value: techuuid,
          };
          this.postSetting(setting);
        }
      }
    );
  };

  handleChangeDescription = (event, uuid) => {
    this.setState(prevState => ({
      settings: prevState.settings.map(setting => {
        if (setting.settinguuid === uuid) {
          setting.description = event.target.value;
        }
        return setting;
      }),
    }));
  };

  handleBlurDescription = (event, uuid) => {
    const prev = event.target.getAttribute(Constants.ATTR_DATA_VALUE);
    if (prev === event.target.value) {
      return;
    }
    event.target.removeAttribute(Constants.ATTR_DATA_VALUE);
    const value = event.target.value;
    let setting = this.state.settings.find(setting => setting.settinguuid === uuid);
    if (setting?.isNew) {
      if (setting.description && setting.value) {
        this.postSetting(setting);
      }
    } else {
      this.putSetting(uuid, value, null);
    }
  };

  handleBlurGlobalValue = (event, uuid) => {
    const prev = event.target.getAttribute(Constants.ATTR_DATA_VALUE);
    if (prev === event.target.value) {
      return;
    }
    event.target.removeAttribute(Constants.ATTR_DATA_VALUE);
    const value = event.target.value;
    this.putGlobalSetting(uuid, value);
  };

  handleBlurValue = (event, uuid, type = "text", validated = false, callbackAfterPut = null, callbackAfterPost = null) => {
    const prev = event.target.getAttribute(Constants.ATTR_DATA_VALUE);
    if (prev === event.target.value) {
      return;
    }
    event.target.removeAttribute(Constants.ATTR_DATA_VALUE);
    const value = event.target.value;
    // Value list and search row values
    if (["listrows", "resultrows"].includes(event.target.id)) {
      if (value < 1 || value > 100) {
        this.setState(prevState => ({
          settings: prevState.settings.map(setting => {
            return setting.settinguuid === uuid ? { ...setting, value: prev } : setting;
          }),
        }));
        this.props.showOverlay({
          text: "Value must be between 1 and 100 inclusive",
          type: Constants.OVERLAY_MESSAGE,
        });
        return;
      }
    }
    // Validate email values
    if (type === "email" && value) {
      if (!value.match(Constants.REGEX_EMAIL)) {
        this.setState(prevState => ({
          settings: prevState.settings.map(setting => {
            return setting.settinguuid === uuid ? { ...setting, value: prev } : setting;
          }),
        }));
        this.props.showOverlay({
          text: "Invalid email address",
          type: Constants.OVERLAY_MESSAGE,
        });
        return;
      }
    } else if (!validated && type === "sku_upc" && value) {
      // Look up the SKU/UPC to make sure it's valid
      this.props.getProduct(value, response => {
        if (response.status === 200) {
          this.handleBlurValue(event, uuid, type, true);
        } else {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "No product found with this Store SKU/UPC.\nNot saved.",
          });
        }
      });
      return;
    }
    let setting = this.state.settings.find(setting => setting.settinguuid === uuid);
    if (setting?.isNew) {
      if (setting.description && setting.value) {
        this.postSetting(setting, () => {
          if (callbackAfterPost) {
            callbackAfterPost(setting);
          }
        });
      }
    } else {
      this.putSetting(uuid, null, value, callbackAfterPut);
    }
  };

  handleBlurClient = event => {
    const id = event.target.id;
    const prev = event.target.getAttribute(Constants.ATTR_DATA_VALUE);
    if (prev === event.target.value) {
      return;
    }
    event.target.removeAttribute(Constants.ATTR_DATA_VALUE);
    const value = event.target.value;
    if (id && value && value !== prev) {
      this.putClient(id, value);
    }
  };

  handleDeleteSetting = (uuid, otheruuid = null) => {
    // If the setting is new, just remove it from the list
    if (this.state.settings.find(s => s.settinguuid === uuid).isNew) {
      this.setState(prevState => ({
        settings: prevState.settings.filter(s => s.settinguuid !== uuid),
      }));
    } else {
      this.props.showOverlay({
        callback: this.maybeDeleteSetting,
        key: { uuid: uuid, otheruuid: otheruuid },
        text: "Are you sure you want to delete?",
        type: Constants.OVERLAY_QUESTION,
        value: null,
      });
    }
  };

  handleDragStart = (event, rowindex, type) => {
    event.dataTransfer.setData("text/plain", event.target.getAttribute("data-uuid"));
    this.dragging_type = type;
    // Add visual cues to the dragged row
  };

  handleDragOver = event => {
    // Enable dropping
    let over_type = null;
    while (over_type === null && event.target !== null) {
      if (event.target.getAttribute("data-type")) {
        over_type = event.target.getAttribute("data-type");
      } else {
        event.target = event.target.parentElement;
      }
    }
    if (over_type !== this.dragging_type) {
      event.preventDefault();
    }
  };

  handleDragEnd = event => {
    // Remove visual cues from the dragged row
    this.dragging_type = null;
  };

  handleDrop = event => {
    const draggedRowUUID = event.dataTransfer.getData("text/plain");
    let droppedOnRowUUID = null;
    let target = event.target;
    while (droppedOnRowUUID === null && target !== null) {
      if (target.getAttribute("data-uuid")) {
        droppedOnRowUUID = target.getAttribute("data-uuid");
      } else {
        target = target.parentElement;
      }
    }
    if (draggedRowUUID === droppedOnRowUUID) {
      event.preventDefault();
      return;
    }
    const start_index = parseInt(this.state.settings.find(setting => setting.settinguuid === draggedRowUUID)?.value);
    const drop_index = parseInt(this.state.settings.find(setting => setting.settinguuid === droppedOnRowUUID)?.value);
    // Get a list of the impacted rows
    let impacted = this.state.settings.filter(setting => {
      return (
        setting.category === Constants.SETTINGS_CATEGORY_CUSTOM_FIELDS &&
        setting.value >= Math.min(start_index, drop_index) &&
        setting.value <= Math.max(start_index, drop_index)
      );
    });
    // Update the impacted rows with their new values
    impacted = impacted.map(setting => {
      if (setting.settinguuid === draggedRowUUID) {
        setting.value = drop_index;
      } else if (start_index < drop_index) {
        setting.value--;
      } else {
        setting.value++;
      }
      return setting;
    });

    // Replace and sort the impacted rows in the settings list
    this.setState(
      prevState => ({
        settings: Helper.sortSettings(
          prevState.settings.map(setting => {
            if (setting.category === Constants.SETTINGS_CATEGORY_CUSTOM_FIELDS) {
              const impactedSetting = impacted.find(s => s.settinguuid === setting.settinguuid);
              if (impactedSetting) {
                setting.value = "" + impactedSetting.value;
              }
            }
            return setting;
          })
        ),
      }),
      () => {
        // Save the settings
        impacted.forEach(setting => {
          this.putSetting(setting.settinguuid, null, "" + setting.value);
        });
      }
    );
  };

  handleBlurGlobalSetting = (event, uuid) => {
    const prev = event.target.getAttribute(Constants.ATTR_DATA_VALUE);
    if (prev === event.target.value) {
      return;
    }
    this.props.showOverlay({
      type: Constants.OVERLAY_QUESTION,
      text: "Are you sure you want to update this setting?",
      callback: (response, uuid, value) => {
        this.maybePutGlobalSetting(response, uuid, value, event);
      },
      key: uuid,
      value: event.target.value,
    });
  };

  handleDeleteGlobalSetting = uuid => {
    this.props.showOverlay({
      callback: this.maybeDeleteGlobalSetting,
      key: uuid,
      text: "Are you sure you want to delete this setting?",
      type: Constants.OVERLAY_QUESTION,
      value: null,
    });
  };

  handleUndeleteGlobalSetting = setting => {
    this.postGlobalSetting(setting.category, setting.description, setting.value, setting.uuid);
  };

  maybeDeleteGlobalSetting = (response, uuid) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      const setting = this.state.globalSettings.filter(setting => setting.settinguuid === uuid);
      this.deleteGlobalSetting(uuid, setting);
    }
  };

  maybePutGlobalSetting = (response, uuid, value, event) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      this.putGlobalSetting(uuid, value);
    } else {
      // Put back the previous value on cancel
      const prev = event.target.getAttribute(Constants.ATTR_DATA_VALUE);
      this.setState(prevState => ({
        settings: prevState.settings.map(setting => {
          if (setting.settinguuid === uuid) {
            setting.value = prev;
          }
          return setting;
        }),
      }));
    }
  };

  getTerminals = () => {
    if (!Helper.isTrue(this.props.appState.handpoint.enabled)) {
      return;
    }
    const url = Constants.URL_PAYMENTS;
    const params = {
      action: Constants.INITIALIZE,
      source: Constants.HANDPOINT,
    };
    Helper.getData(url, params).then(response => {
      if (
        response.status >= 200 &&
        response.status < 300 &&
        response.body?.message &&
        Array.isArray(response.body.message) &&
        response.body.message.length > 0
      ) {
        this.setState(
          {
            error: null,
            downloading: false,
            terminals: response.body.message,
          },
          () => {
            // Make sure each terminal is listed in settings
            let newSettings = [];
            let index = this.state.settings.length;
            this.state.terminals.forEach(terminal => {
              const setting = this.state.settings.find(
                s => s.category === Constants.SETTINGS_CATEGORY_HANDPOINT && s.description === terminal.serial_number
              );
              if (!setting) {
                const newSetting = {
                  settinguuid: index,
                  uuid: index,
                  category: Constants.SETTINGS_CATEGORY_HANDPOINT,
                  description: terminal.serial_number,
                  value: "",
                  isNew: true,
                };
                newSettings.push(newSetting);
                index++;
              }
            });
            this.setState(prevState => ({
              settings: prevState.settings.concat(newSettings),
            }));
          }
        );
      } else {
        this.setState({
          displaycardreaderstate: "Error getting card reader list",
        });
      }
    });
  };

  getValue = (category, description, isBoolean = false) => {
    const setting = this.state.settings.find(s => s.category === category && s.description === description);
    if (isBoolean) {
      return Helper.isTrue(setting?.value);
    }
    return setting?.value ?? "";
  };

  getValues = (category, description = null) => {
    let settings = this.state.settings.filter(s => s.category === category && (s.description === description || !description));
    settings = settings.map(s => {
      return { ...s, editable: () => true };
    });
    return settings;
  };

  getUUID = (category, description, value = null) => {
    const setting = this.state.settings.find(s => s.category === category && s.description === description && (!value || s.value === value));
    return setting ? setting.settinguuid : "";
  };

  maybeCheckEmailVerification = () => {
    const email = this.getValue(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_FROM_ADDRESS);
    const emailVerified = this.getValue(Constants.SETTINGS_CATEGORY_CLIENT, Constants.SETTINGS_FROM_VERIFIED);
    if (email && emailVerified === Constants.MAYBE) {
      this.getCheckEmailVerification();
    }
  };

  maybeDeleteSetting = (response, uuids, prev) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      const uuid = uuids.uuid;
      this.deleteSetting(uuid);
      // If this is a terminal friendly name setting, delete the terminal setting as well
      if (uuids.otheruuid) {
        this.deleteSetting(uuids.otheruuid);
      }
    }
  };

  maybeDeleteUser = (response, uuid, prev) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      this.deleteUser(uuid);
    }
  };

  getSettings = callback => {
    this.setState({
      downloading: true,
    });
    const params = {};
    const url = Constants.URL_SETTINGS;
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body.records) {
        let settings = response.body.records;
        settings = Helper.sortSettings(settings);

        this.setState({ settings: settings }, () => {
          if (callback) {
            callback();
          }
        });
      } else {
        console.log(Helper.clTimestamp(), "Error");
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "Error getting settings",
        });
      }
      this.setState({
        downloading: false,
      });
    });
  };

  getUsers = () => {
    this.setState({
      downloading: true,
    });
    const params = {};
    const url = Constants.URL_USERS;
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body.records) {
        let users = response.body.records;
        this.setState({ users: users });
      } else {
        console.log(Helper.clTimestamp(), "Error");
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "Error getting user list",
        });
      }
      this.setState({
        downloading: false,
      });
    });
  };

  getClient = callback => {
    this.setState({
      downloading: true,
    });
    const params = { clientuuid: this.props.appState.clientuuid };
    const url = Constants.URL_CLIENTS;
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        let client = response.body;
        this.setState({ client: client });
        if (callback) {
          callback(client);
        }
      } else {
        console.log(Helper.clTimestamp(), "Error");
      }
      this.setState({
        downloading: false,
      });
    });
  };

  getEnterprises = () => {
    this.setState({
      downloading: true,
    });
    const params = { q: Constants.ACTION_GET_ENTERPRISES };
    const url = Constants.URL_CLIENTS;
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        this.setState({ enterprises: response.body });
      } else {
        console.log(Helper.clTimestamp(), "Error");
      }
      this.setState({
        downloading: false,
      });
    });
  };

  getCheckEmailVerification = (callback = null) => {
    this.setState({
      downloading: true,
    });
    const params = { action: Constants.CHECK_EMAIL_VERIFICATION };
    const url = Constants.URL_MAIL;
    Helper.getData(url, params).then(response => {
      if (response.status === 200 || response.status === 202 || response.status === 404) {
        this.setState(
          prevState => ({
            settings: prevState.settings.map(item => {
              if (item.category === Constants.SETTINGS_CATEGORY_CLIENT && item.description === Constants.SETTINGS_FROM_VERIFIED) {
                item.value = response.status === 200 ? Constants.TRUE : response.status === 202 ? Constants.MAYBE : Constants.FALSE;
              }
              return item;
            }),
          }),
          () => {
            if (callback) {
              callback(response);
            }
          }
        );
      }
      this.setState({
        downloading: false,
      });
    });
  };

  handleAddGlobalSetting = () => {
    this.props.showOverlay({
      type: Constants.OVERLAY_INPUT_BOX,
      text: "Setting Category",
      maxLength: 255,
      okButtonLabel: "Next",
      callback: (response, key, category) => {
        if (response === Constants.OVERLAY_RESPONSE_OK) {
          this.props.showOverlay({
            type: Constants.OVERLAY_INPUT_BOX,
            text: "Setting Description",
            maxLength: 255,
            okButtonLabel: "Create",
            callback: (response, key, description) => {
              if (response === Constants.OVERLAY_RESPONSE_OK) {
                this.postGlobalSetting(category, description);
              } else {
                this.props.hideOverlay();
              }
            },
          });
        } else {
          this.props.hideOverlay();
        }
      },
    });
  };

  handleGetGlobalSettings = () => {
    //Protect screen during downloading data
    this.props.showOverlay({
      type: Constants.OVERLAY_PROGRESS,
      text: "Loading global settings...",
    });
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_SETTINGS;
    const data = { global_setting: true };
    Helper.getData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        const globalSettings = Helper.sortSettings(response.body);
        this.setState(
          {
            globalSettings: globalSettings,
          },
          () => {
            this.setState({ downloading: false }, () => {
              this.props.hideOverlay();
            });
          }
        );
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "GET", downloading: false }, () => {
          this.props.showOverlay({
            text: "There was an error loading the global settings",
            type: Constants.OVERLAY_MESSAGE,
          });
        });
      }
    });
  };

  deleteSetting = (settinguuid, callback) => {
    if (!settinguuid) {
      return;
    }
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_SETTINGS;
    const data = { settinguuid: settinguuid };
    Helper.deleteData(url, data).then(response => {
      if (response.status === 200 && response.body?.records) {
        const settings = Helper.sortSettings(response.body.records);
        this.setState(
          {
            settings: settings,
          },
          () => {
            if (callback) {
              callback();
            }
            this.setState({ downloading: false }, () => {
              this.props.hideOverlay();
            });
          }
        );
      } else if (response.status === 400) {
        this.setState({ downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Cannot delete system setting.",
          });
          this.props.hideOverlay();
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            text: "There was an error deleting the setting.",
            type: Constants.OVERLAY_MESSAGE,
          });
        });
      }
    });
  };

  deleteUser = useruuid => {
    //Protect screen during downloading data
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_USERS;
    const data = { useruuid: useruuid };
    Helper.deleteData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        // Remove the user from the list
        this.setState(prevState => ({
          downloading: false,
          users: prevState.users.filter(user => user.uuid !== useruuid),
        }));
      } else if (response.status === 409) {
        this.setState({ downloading: false }, () => {
          this.props.showOverlay({
            text: "Cannot delete a user that is assigned as a technician.",
            type: Constants.OVERLAY_MESSAGE,
          });
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            text: "There was an error adding the user.",
            type: Constants.OVERLAY_MESSAGE,
          });
        });
      }
    });
  };

  deleteGlobalSetting = (uuid, setting) => {
    //Protect screen during downloading data
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_SETTINGS;
    const data = { global_setting: true, settinguuid: uuid };
    Helper.deleteData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        // Remove the setting from the list and add it to the deleted settings list
        this.setState(prevState => ({
          downloading: false,
          globalSettings: prevState.globalSettings.filter(setting => setting.settinguuid !== uuid),
          deletedSettings: prevState.deletedSettings.concat(setting),
        }));
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            text: "There was an error deleting the global setting.",
            type: Constants.OVERLAY_MESSAGE,
          });
        });
      }
    });
  };

  postGlobalSetting = (category, description, value, uuid) => {
    //Protect screen during downloading data
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_SETTINGS;
    const setting = {
      global_setting: true,
      category: category,
      description: description,
      value: value ?? "",
    };
    if (uuid) {
      setting.settinguuid = uuid;
    }
    Helper.postData(url, setting).then(response => {
      if (response.status === 200 && response.body) {
        this.setState(
          prevState => ({
            globalSettings: [response.body].concat(prevState.globalSettings),
            deletedSettings: prevState.deletedSettings.filter(setting => setting.settinguuid !== uuid),
            downloading: false,
          }),
          () => {
            this.props.hideOverlay();
          }
        );
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false }, () => {
          this.props.hideOverlay();
        });
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            text: "There was an error adding the global setting.",
            type: Constants.OVERLAY_MESSAGE,
          });
        });
      }
    });
  };

  postSetting = (setting, callback = null) => {
    //Protect screen during downloading data
    this.setState({ downloading: true });

    // Grab the uuid
    const uuid = setting.settinguuid;

    // Copy and clean up the object
    setting = Helper.deepCopy(setting);
    delete setting.isNew;
    delete setting.uuid; // Do we need this?
    delete setting.settinguuid;

    //set up to make database call
    const url = Constants.URL_SETTINGS;
    Helper.postData(url, setting).then(response => {
      if (response.status === 200 && response.body) {
        this.setState(
          prevState => ({
            downloading: false,
            settings: prevState.settings.map(item => {
              if (item.settinguuid === uuid) {
                item.settinguuid = response.body.settinguuid;
                item.uuid = item.settinguuid;
                item.isNew = false;
              }
              return item;
            }),
          }),
          () => {
            if (callback) {
              callback(response.body);
            }
            this.props.hideOverlay();
          }
        );
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false }, () => {
          this.props.hideOverlay();
        });
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            text: "There was an error adding the setting.",
            type: Constants.OVERLAY_MESSAGE,
          });
        });
      }
    });
  };

  putGlobalSetting = (uuid, value) => {
    // If nothing to do, return
    if (!uuid) {
      return;
    }
    this.setState({ downloading: true });

    const url = Constants.URL_SETTINGS;
    // Including the category as "SYSTEM" will invoke the global setting update logic
    let params = { settinguuid: uuid, value: value, global_setting: true };
    Helper.putData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        // Good response
        this.props.handleSettingsUpdated(this.state.settings);
        this.setState({ downloading: false });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            text: "There was an error updating the setting.",
            type: Constants.OVERLAY_MESSAGE,
          });
        });
      }
    });
  };

  putSetting = (uuid, description, value, callback = null) => {
    // If nothing to do, return
    if (!uuid) {
      return;
    }
    // Grab the setting from the list
    let setting = this.state.settings.find(item => item.settinguuid === uuid);

    // TODO: If blank, make sure this setting category/description allows blank description/value
    if (!setting.description || !setting.value) {
    }

    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_SETTINGS;
    let params = { settinguuid: uuid };
    if (description !== null) {
      params.description = description;
    }
    if (value !== null) {
      params.value = value;
    }
    Helper.putData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        // Good response
        this.props.handleSettingsUpdated(this.state.settings);
        if (callback) {
          callback();
        }
        this.setState({ downloading: false }, () => {
          this.props.hideOverlay();
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            text: "There was an error updating the setting.",
            type: Constants.OVERLAY_MESSAGE,
          });
        });
      }
    });
  };

  putUser = (user, callback = null) => {
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_USERS;
    Helper.putData(url, user).then(response => {
      if (response.status === 200 && response.body) {
        // Good response
        this.setState({ downloading: false }, () => {
          if (callback) {
            callback();
          }
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            text: "There was an error updating the user.",
            type: Constants.OVERLAY_MESSAGE,
          });
        });
      }
    });
  };

  postUser = (uuid, user) => {
    if (!uuid) {
      return;
    }
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_USERS;
    Helper.postData(url, user).then(response => {
      if (response.status === 200 && response.body?.useruuid) {
        // Good response, remove the isNew flag from the user
        const newuseruuid = response.body.useruuid;
        this.setState(prevState => ({
          downloading: false,
          users: prevState.users.map(item => {
            if (item.uuid === uuid) {
              item.uuid = newuseruuid;
              item.useruuid = newuseruuid;
              item.isNew = false;
            }
            return item;
          }),
        }));
      } else if (response.status === 409) {
        this.setState({ error: "POST", downloading: false });
        this.props.showOverlay({
          text: "Email address is already registered.",
          type: Constants.OVERLAY_MESSAGE,
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            text: "There was an error adding the user.",
            type: Constants.OVERLAY_MESSAGE,
          });
        });
      }
    });
  };

  postEcommerce = (clientuuid, action) => {
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_ECOMMERCE;
    const data = { clientuuid: clientuuid, action: action };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        // Good response
        this.setState({ downloading: false }, () => {
          if (response.body.message) {
            this.props.showOverlay({
              text: response.body.message,
              type: Constants.OVERLAY_MESSAGE,
            });
          }
          if (response.body.sellerid) {
            this.setState(prevState => ({
              settings: [
                ...prevState.settings,
                { category: Constants.SETTINGS_CATEGORY_CARTLOOM, description: Constants.SETTINGS_SELLERID, value: response.body.sellerid },
              ],
            }));
          }
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            text: "There was an error updating the client.",
            type: Constants.OVERLAY_MESSAGE,
          });
        });
      }
    });
  };

  putClient = (id, value, action = null) => {
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_CLIENTS;
    let data = { uuid: this.props.appState.clientuuid, [id]: value };
    if (action) {
      data.action = action;
    }
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        // Good response
        this.setState({ downloading: false, client: response.body }, () => {
          this.getEnterprises();
          this.props.hideOverlay();
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false }, () => {
          this.props.hideOverlay();
        });
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            text: "There was an error updating the client.",
            type: Constants.OVERLAY_MESSAGE,
          });
        });
      }
    });
  };
}

export default Settings;
