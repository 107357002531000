import React from "react";

import { animated, useSpring } from "@react-spring/web";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Capo from "../img/Capo.js";

function NewsWidget(props) {
  const [expanded, setExpanded] = React.useState(false);
  const openAnimation = useSpring({
    from: { maxHeight: "6em" },
    to: { maxHeight: expanded ? "100em" : "6em" },
    config: { duration: "400" },
  });
  let title = "Latest Updates";
  if (props.appState?.clientSettings?.NAME) {
    title = "Updates for " + props.appState?.clientSettings?.NAME;
  }
  const expanderIcon = expanded ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />;
  const expander = (
    <div
      className="dbwExpander"
      onClick={() => {
        setExpanded(!expanded);
      }}
    >
      {expanderIcon}
    </div>
  );
  return (
    <animated.div
      className="dashboardWidget dbwNews"
      onClick={() => {
        setExpanded(!expanded);
      }}
      style={openAnimation}
    >
      <div className="dbwTitle">
        <span className="newsLogo">
          <Capo />
        </span>
        <span data-testid="Updates for">{title}</span>
      </div>
      <hr />
      <h3 className="white">Latest Enhancements</h3>
      <ul>
        <li>
          <span className="highlight">E-Commerce website and online sales</span> feature is now entering beta testing and will be rolled out to all
          enrolled customers in the near future. Please contact Chris Mitchell if you are interested in upgrading. [9/17/2024]
        </li>
        <li>
          Custom fields for billing subscriptions are now stored in the ClerkHound database for improved performance. A separate search field has been
          temporarily added to the Subscription list. [9/17/2024]
        </li>
        <li>Change can no longer be given for non-cash payment types (check, PayPal, etc). [9/17/2024]</li>
        <li>Campaigns now support collection of name and phone number on the invitation step. [8/14/2024]</li>
        <li>Tender amount fields are now protected from non-numeric characters. [8/14/2024]</li>
      </ul>
      <h3 className="white">Bug fixes</h3>
      <ul>
        <li>Improved the Messages list to properly display emails sent to prospects that do not yet have an associated name. [9/17/2024]</li>
        <li>Improved the ability to go directly to a quote, order, invoice, etc. by entering "Q-123" or "I-456" in the search widget. [9/17/2024]</li>
        <li>When importing a true/false value, blanks are now treated as "True". [8/30/2024]</li>
        <li>
          Added better error messaging for customer-facing pages, such as pay an invoice, complete a campaign form, and vault a credit card.
          [8/23/2024]
        </li>
      </ul>
      {expander}
    </animated.div>
  );
}

export default NewsWidget;
